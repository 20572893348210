const DiverFalling = (props) => {
    return (
        <svg
            {...props}
            viewBox="0 0 334 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="150.271"
                y="99"
                width="13.3516"
                height="5.36"
                rx="2.68"
                fill="#07167C"
            />
            <rect
                width="19.2812"
                height="5.34067"
                rx="2.67034"
                transform="matrix(-0.0235666 0.999722 -0.999718 -0.0237375 159.973 100.997)"
                fill="#07167C"
            />
            <path
                d="M136.746 126.456C137.271 116.041 145.929 107.933 156.357 107.933V107.933C167.6 107.933 176.579 117.3 176.104 128.533L174.307 171L176.091 212.456C176.573 223.656 167.623 233 156.413 233V233C145.972 233 137.325 224.843 136.819 214.414C135.296 183.045 135.084 159.436 136.746 126.456Z"
                fill="#F7BA16"
            />
            <path
                d="M216.594 273.845C211.749 246.256 207.422 231.161 195.617 205.183C180.981 214.001 170.683 216.032 168.453 233.5C182.731 268.361 193.833 283.732 207.953 292.28C215.141 292.267 218.919 291.774 216.594 273.845Z"
                fill="#071B81"
            />
            <path
                d="M122.285 273.868C127.129 246.279 131.457 231.184 143.262 205.206C157.898 214.024 168.196 216.055 170.426 233.522C156.148 268.384 145.046 283.754 130.926 292.302C123.738 292.289 119.96 291.796 122.285 273.868Z"
                fill="#071B81"
            />
            <path
                d="M139.086 213.559C143.225 180.554 143.371 160.713 138.586 120.575C159.561 104.686 173.831 105.604 199.586 120.575C191.463 156.886 193.279 177.248 199.586 213.559C177.671 235.007 164.321 235.502 139.086 213.559Z"
                fill="#071B81"
            />
            <path
                d="M195.181 212.168C175.886 215.526 164.627 215.688 144.835 212.482C144.384 212.409 143.945 212.691 143.833 213.134C143.702 213.649 144.06 214.162 144.588 214.224C164.359 216.551 175.962 216.627 195.5 214.239C196.081 214.168 196.482 213.614 196.361 213.041L196.341 212.946C196.229 212.419 195.713 212.076 195.181 212.168Z"
                fill="#2E50CB"
            />
            <path
                d="M167.263 112.459C167.064 151.214 167.834 173.41 168.183 213.392C168.186 213.72 168.374 214.025 168.669 214.17C169.252 214.456 169.931 214.011 169.917 213.362C169.126 175.447 168.701 152.832 169.342 112.453C169.354 111.731 168.62 111.222 167.954 111.502L167.875 111.535C167.506 111.691 167.265 112.058 167.263 112.459Z"
                fill="#FFFFFD"
            />
            <path
                d="M292.062 121.871C282.138 120.047 275.98 119.298 263.021 120.538C251.842 122.615 247.72 123.168 242.453 126.81C242.155 127.016 241.996 127.375 242.048 127.734C242.727 132.43 244.679 132.861 250.424 134.871C250.536 134.91 250.658 134.93 250.776 134.928C266.791 134.664 276.106 133.575 292.156 130.714C292.78 130.602 293.144 129.946 292.905 129.359C292.038 127.236 292 126.869 292.855 123.079C292.98 122.524 292.621 121.974 292.062 121.871Z"
                fill="#F79E85"
            />
            <path
                d="M42.409 121.871C52.3328 120.047 58.4904 119.298 71.4498 120.538C82.6292 122.615 86.7505 123.168 92.0174 126.81C92.316 127.016 92.4749 127.375 92.4229 127.734C91.7432 132.43 89.7917 132.861 84.0463 134.871C83.9348 134.91 83.8126 134.93 83.6945 134.928C67.6794 134.664 58.365 133.575 42.3142 130.714C41.6903 130.602 41.3262 129.946 41.5658 129.359C42.4328 127.236 42.471 126.869 41.6158 123.079C41.4908 122.524 41.8502 121.974 42.409 121.871Z"
                fill="#F79E85"
            />
            <path
                d="M249.094 124.242C227.658 121.479 216.439 118.727 191.727 119.979C190.622 120.035 189.77 120.986 189.831 122.091C190.167 128.152 190.51 129.806 199.222 136.136C199.598 136.409 200.073 136.544 200.537 136.517C219.035 135.45 226.771 140.816 247.46 136.84C248.013 136.733 248.501 136.387 248.807 135.915C251.807 131.288 253.983 131.185 250.556 125.205C250.252 124.675 249.7 124.32 249.094 124.242Z"
                fill="#F79E85"
            />
            <path
                d="M85.3768 124.242C106.813 121.479 118.032 118.727 142.744 119.979C143.848 120.035 144.701 120.986 144.64 122.091C144.303 128.152 143.96 129.806 135.248 136.136C134.873 136.409 134.398 136.544 133.934 136.517C115.436 135.45 107.699 140.816 87.0106 136.84C86.4582 136.733 85.97 136.387 85.6639 135.915C82.6634 131.288 80.4879 131.185 83.9144 125.205C84.2184 124.675 84.7704 124.32 85.3768 124.242Z"
                fill="#F79E85"
            />
            <path
                d="M310.319 110.245C309.074 108.891 301.88 113.701 291.096 122.916C289.712 127.024 291.03 128.168 293.686 131.779C299.682 131.298 303.08 132.639 306.245 133.353C312.102 134.659 320.384 135.974 321.077 134.713C321.769 133.451 312.199 129.755 312.199 129.755C318.209 130.405 326.073 130.745 326.431 129.792C326.789 128.838 319.166 127.12 312.985 125.909C319.491 125.615 326.642 123.976 328.316 122.438C329.989 120.9 321.044 121.023 312.527 120.709C319.882 118.531 326.302 115.544 325.057 114.19C323.812 112.835 315.517 115.711 305.875 116.829C305.875 116.829 311.564 111.6 310.319 110.245Z"
                fill="#F79E85"
            />
            <path
                d="M24.1516 110.245C25.3964 108.891 32.5905 113.701 43.3747 122.916C44.7586 127.024 43.4406 128.168 40.7851 131.779C34.7889 131.298 31.3904 132.639 28.2257 133.353C22.3683 134.659 14.0869 135.974 13.3941 134.713C12.7013 133.451 22.2721 129.755 22.2721 129.755C16.2621 130.405 8.39757 130.745 8.03944 129.792C7.6813 128.838 15.305 127.12 21.4854 125.909C14.9799 125.615 7.82824 123.976 6.1549 122.438C4.48155 120.9 13.4267 121.023 21.9436 120.709C14.5892 118.531 8.16906 115.544 9.41387 114.19C10.6587 112.835 18.9542 115.711 28.5962 116.829C28.5962 116.829 22.9068 111.6 24.1516 110.245Z"
                fill="#F79E85"
            />
            <path
                d="M139.586 59.5C139.586 63.366 137.124 66.5 134.086 66.5C131.048 66.5 128.586 63.366 128.586 59.5C128.586 55.634 131.048 52.5 134.086 52.5C137.124 52.5 139.586 55.634 139.586 59.5Z"
                fill="#F9AE91"
            />
            <path
                d="M209.586 63C209.586 66.866 207.124 70 204.086 70C201.048 70 198.586 66.866 198.586 63C198.586 59.134 201.048 56 204.086 56C207.124 56 209.586 59.134 209.586 63Z"
                fill="#F9AE91"
            />
            <path
                d="M135.372 35.0518C134.464 43.8687 135.367 48.2718 137.385 54.9931C137.505 55.393 137.862 55.6754 138.279 55.7012L201.42 59.617C201.823 59.642 202.203 59.42 202.381 59.0578C206.213 51.2967 208.85 46.7849 206.381 39.9278C206.219 39.4781 206.376 38.9714 206.767 38.6963C212.038 34.9877 216.045 29.0969 215.086 27.6583C214.086 26.1583 207.019 29.0298 204.086 31.6583C205.247 26.1877 209.086 11.1582 207.086 10.1583C205.306 9.26819 193.28 14.8792 187.084 18.7993C186.33 19.2762 185.278 18.6402 185.338 17.7502C185.846 10.1531 183.94 1.51241 182.586 0.15826C181.282 -1.14592 171.926 5.86025 165.99 11.9884C165.285 12.7161 163.873 12.0914 163.913 11.0791C164.099 6.29229 163.29 2.05966 162.086 1.65826C160.797 1.22845 152.06 8.1844 147.141 14.2779C146.507 15.0632 144.695 14.6407 144.182 13.7714C143.467 12.5573 142.448 11.8711 141.586 12.1583C140.196 12.6217 138.968 19.8631 139.839 24.5897C139.946 25.1693 139.587 25.7507 139.017 25.9004C134.746 27.0221 128.586 30.2786 128.586 31.6583C128.586 32.8274 131.39 33.8429 133.756 33.6436C134.559 33.576 135.454 34.25 135.372 35.0518Z"
                fill="#CE5425"
            />
            <path
                d="M165.486 103.5C152.387 102 139.174 88.1161 136.65 63.8431C136.185 54.1157 137.935 48.9822 145.851 40.8424C148.179 38.4483 151.229 36.8958 154.548 36.5256C162.474 35.6416 176.063 35.1226 186.895 37.9536C188.567 38.3907 190.138 39.1613 191.498 40.2281C200.019 46.9133 201.951 54.4406 201.029 68.1425C198.155 92.2584 178.586 105 165.486 103.5Z"
                fill="#F9AE91"
            />
            <path
                d="M195.857 48.6662C179.116 44.0401 167.442 43.7613 147.774 44.1012C145.448 44.1413 143.062 44.5025 141.128 45.7955C139.032 47.1969 137.971 48.8622 136.948 51.6662C135.545 56.6712 135.142 59.8511 136.134 64.2215C136.581 66.1934 137.54 68.0191 138.849 69.5599C142.272 73.5876 144.482 74.4384 147.896 74.6662C152.714 74.8889 154.324 74.836 156.758 74.6662C158.215 74.5839 159.229 74.3615 160.041 73.9583C162.047 72.9615 162.539 70.3873 163.885 68.597C164.462 67.8305 165.154 67.2868 165.989 66.7977C166.757 66.3483 167.647 66.1662 168.537 66.1662C170.013 66.1662 171.496 66.6742 172.422 67.8244C172.949 68.4792 173.304 69.1006 173.551 69.8036C173.97 70.9977 174.218 72.2625 174.852 73.358C175.505 74.4883 176.119 75.2833 176.783 75.8979C178.443 77.4359 180.859 77.714 183.122 77.7713C186.269 77.8511 188.586 77.8534 190.997 77.7959C193.515 77.7358 196.062 77.2086 198.123 75.761C200.581 74.0346 201.235 72.4808 202.113 70.1662C203.359 65.5817 203.768 62.562 203.514 58.6545C203.364 56.3432 202.664 54.0621 201.271 52.2117C199.73 50.1657 198.231 49.2201 195.857 48.6662Z"
                fill="#071B81"
            />
            <path
                d="M192.635 50.8899C177.763 47.2023 167.387 46.9764 149.93 47.2463C147.82 47.2789 145.655 47.557 143.859 48.663C142.064 49.7682 141.139 51.0887 140.247 53.2838C139.092 56.9814 138.699 59.4306 139.346 62.5412C139.783 64.639 140.906 66.539 142.418 68.0578C145.195 70.8476 147.109 71.4649 149.983 71.637C154.267 71.8147 155.699 71.7725 157.864 71.637C159.569 71.5505 160.236 70.1652 160.779 68.7318C161.443 66.9769 162.048 65.0898 163.24 63.641C163.407 63.4381 163.585 63.2532 163.774 63.0822C165.126 61.8631 167.093 61.7419 168.907 61.8869C171.179 62.0685 173.546 62.7173 175.135 64.3505C175.15 64.3655 175.164 64.3805 175.179 64.3956C176.092 65.3594 176.376 66.7619 176.956 67.956C177.644 69.3724 178.304 70.7982 179.044 71.9301C180.167 73.6495 182.314 74.0692 184.367 74.1233C186.142 74.1701 187.117 74.1769 188.24 74.1513C190.531 74.0989 192.857 73.6714 194.782 72.4265C196.86 71.0822 197.433 69.8555 198.198 68.0461C199.184 64.7928 199.581 62.5271 199.497 59.8723C199.419 57.3579 198.606 54.8192 196.829 53.0382C195.659 51.8659 194.428 51.2652 192.635 50.8899Z"
                fill="#FCD7CB"
            />
            <ellipse
                cx="152.268"
                cy="60.4995"
                rx="4.17055"
                ry="4.5"
                fill="#171F61"
            />
            <ellipse
                cx="185.633"
                cy="62.4995"
                rx="4.17055"
                ry="4.5"
                fill="#171F61"
            />
            <path
                d="M152.268 50.9995C150.323 51.0562 149.092 51.3822 147.522 52.2737C146.635 52.7773 146.214 53.9753 147.086 54.5035C147.862 54.9734 149.009 54.4858 149.838 54.1191C150.191 53.9632 150.627 53.796 151.159 53.6059C151.873 53.351 152.659 53.3608 153.36 53.648C153.598 53.7451 153.811 53.8369 154.002 53.9255C154.964 54.3705 156.38 55.0953 157.069 54.2905C157.566 53.7097 157.168 52.8877 156.565 52.4168C155.505 51.588 154.42 51.1746 152.268 50.9995Z"
                fill="#BD5930"
            />
            <path
                d="M187.471 53.389C185.564 53.012 184.287 53.056 182.542 53.576C181.559 53.8687 180.862 54.9357 181.58 55.6435C182.223 56.2763 183.46 56.0558 184.36 55.8832C184.739 55.8104 185.201 55.7449 185.761 55.6786C186.518 55.5889 187.282 55.7735 187.896 56.2094C188.101 56.355 188.284 56.4903 188.448 56.6177C189.279 57.2659 190.486 58.2878 191.35 57.6548C191.97 57.2003 191.783 56.3162 191.312 55.7259C190.48 54.682 189.524 54.0382 187.471 53.389Z"
                fill="#BD5930"
            />
            <path
                d="M161.76 80.9999C159.015 82.3984 157.99 83.5813 158.178 87.233"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M158.586 84C167.298 90.6348 171.813 90.0137 179.586 85.9331"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M158.886 114.5C160.844 109.404 161.413 106.299 161.419 100.3L173.994 101.497C173.721 106.837 174.066 110.001 175.923 116.09C169.268 119.051 165.545 119.094 158.886 114.5Z"
                fill="#F9AE91"
                stroke="#F9AE91"
            />
            <path
                d="M139.606 115.533C139.955 112.934 142.183 111 144.806 111V111C148.682 111 151.218 115.046 149.701 118.614C143.904 132.237 142.403 142.146 146.971 156.088C147.907 158.943 145.87 162 142.866 162V162C140.749 162 138.954 160.431 138.708 158.329C136.878 142.706 137.363 132.216 139.606 115.533Z"
                fill="#2E50CB"
            />
            <path
                d="M197.146 116.533C196.797 113.934 194.569 112 191.946 112V112C188.07 112 185.534 116.046 187.051 119.614C192.848 133.237 194.349 143.146 189.781 157.088C188.845 159.943 190.882 163 193.886 163V163C196.003 163 197.798 161.431 198.044 159.329C199.874 143.706 199.389 133.216 197.146 116.533Z"
                fill="#2E50CB"
            />
            <path
                d="M228.48 243.16C214.032 260.617 210.642 266.566 204.126 286.564C208.023 292.888 205.845 293.522 213.657 292.933C231.491 275.126 235.858 266.746 236.255 246.6C234.435 244.523 231.386 243.172 228.48 243.16Z"
                fill="#071B81"
            />
            <path
                d="M296.222 258.056C296.522 254.926 262.905 224.389 236.188 224.273C230.477 227.546 230.574 230.075 232.677 234.991C243.101 259.701 263.809 289.245 265.978 288.261C268.148 287.276 269.649 280.707 271.781 274.146C275.465 274.926 279.6 276.323 282.309 274.725C285.018 273.126 283.597 267.719 283.523 262.419C289.452 261.644 295.922 261.186 296.222 258.056Z"
                fill="#FFFFFD"
            />
            <path
                d="M276.125 253.239C268.786 246.145 256.18 237.228 254.747 236.813C253.315 236.398 253.391 238.108 253.391 238.108C262.819 243.96 267.12 248.207 274.227 255.051C275.532 255.12 276.053 254.881 276.125 253.239Z"
                fill="#0E196C"
            />
            <path
                d="M264.65 264.985C257.263 257.94 247.84 245.708 247.367 244.294C246.894 242.879 248.605 242.886 248.605 242.886C254.837 252.068 259.255 256.191 266.384 263.013C266.506 264.314 266.288 264.845 264.65 264.985Z"
                fill="#0E196C"
            />
            <path
                d="M235.137 231.994C233.274 235.847 232.084 239.929 228.277 243.615L236.494 247.251C236.954 242.64 239.365 241.224 241.917 234.994C244.47 228.763 235.708 229.63 235.137 231.994Z"
                fill="#F79E85"
            />
            <path
                d="M107.416 242.381C122.603 259.199 126.246 264.997 133.618 284.696C129.996 291.181 132.2 291.721 124.37 291.469C105.785 274.447 101.062 266.263 99.7965 246.153C101.525 243.999 104.514 242.518 107.416 242.381Z"
                fill="#071B81"
            />
            <path
                d="M35.6261 252.809C35.4955 249.667 70.712 220.988 97.3963 222.315C102.922 225.891 102.689 228.411 100.324 233.206C88.5816 257.318 66.3093 285.702 64.1962 284.601C62.0831 283.501 60.9383 276.86 59.1636 270.194C55.4433 270.774 51.2383 271.946 48.6197 270.204C46.001 268.461 47.7116 263.139 48.0717 257.851C42.1926 256.756 35.7568 255.95 35.6261 252.809Z"
                fill="#FFFFFD"
            />
            <path
                d="M55.9529 249.083C63.6649 242.396 76.7332 234.172 78.1861 233.835C79.639 233.498 79.4706 235.201 79.4706 235.201C69.7405 240.535 65.2172 244.544 57.7513 250.995C56.4444 250.993 55.9367 250.727 55.9529 249.083Z"
                fill="#0E196C"
            />
            <path
                d="M66.7789 261.431C74.5351 254.795 84.6049 243.09 85.1534 241.703C85.7018 240.316 83.993 240.23 83.993 240.23C77.2752 249.063 72.6406 252.942 65.1544 259.369C64.9622 260.662 65.1507 261.204 66.7789 261.431Z"
                fill="#0E196C"
            />
            <path
                d="M100.282 231.512C102.31 235.282 103.675 239.309 107.637 242.828L99.5844 246.814C98.9265 242.227 96.4566 240.916 93.6381 234.802C90.8197 228.687 99.6104 229.176 100.282 231.512Z"
                fill="#F79E85"
            />
        </svg>
    );
};

export default DiverFalling;
