import React from "react";

const BannerBottom = (props) => {
    return (
        <svg
            {...props}
            viewBox="0 0 467 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M160.704 0H0.26013C0.26013 0 -1.29422 44.0287 3.19613 44.0287C7.68649 44.0287 8.65364 24.6561 16.6672 14.6762C24.6808 4.6964 35.3192 35.9568 30.8291 44.0287C26.339 52.1007 47.7888 18.0518 59.671 27.1511C71.5532 36.2503 67.2698 52.1007 72.9693 52.1007C78.6689 52.1007 81.2592 30.0863 81.2592 30.0863C81.2592 30.0863 90.0675 58.3381 89.031 52.1007C87.9945 45.8633 101.673 24.2158 103.884 24.2158C106.647 24.2158 109.238 30.0863 114.937 34.4892C120.636 38.8921 120.636 34.4892 122.709 30.0863C124.367 26.564 127.429 31.5539 128.753 34.4892C130.653 35.223 134.66 36.2503 135.489 34.4892C136.318 32.728 138.482 26.9065 139.461 24.2158C150.125 34.0163 147.177 40.478 141.015 52.1007C157.669 51.843 162.302 38.4338 160.704 0Z"
                fill="#213039"
            />
            <path
                d="M466.704 0H306.26C306.26 0 304.706 44.0287 309.196 44.0287C313.686 44.0287 314.654 24.6561 322.667 14.6762C330.681 4.6964 341.319 35.9568 336.829 44.0287C332.339 52.1007 353.789 18.0518 365.671 27.1511C377.553 36.2503 373.27 52.1007 378.969 52.1007C384.669 52.1007 387.259 30.0863 387.259 30.0863C387.259 30.0863 396.068 58.3381 395.031 52.1007C393.994 45.8633 407.673 24.2158 409.884 24.2158C412.647 24.2158 415.238 30.0863 420.937 34.4892C426.636 38.8921 426.636 34.4892 428.709 30.0863C430.367 26.564 433.429 31.5539 434.753 34.4892C436.653 35.223 440.66 36.2503 441.489 34.4892C442.318 32.728 444.482 26.9065 445.461 24.2158C456.125 34.0163 453.177 40.478 447.015 52.1007C463.669 51.843 468.302 38.4338 466.704 0Z"
                fill="#213039"
            />
            <path
                d="M317.704 0H157.26C157.26 0 155.706 44.0287 160.196 44.0287C164.686 44.0287 165.654 24.6561 173.667 14.6762C181.681 4.6964 192.319 35.9568 187.829 44.0287C183.339 52.1007 204.789 18.0518 216.671 27.1511C228.553 36.2503 224.27 52.1007 229.969 52.1007C235.669 52.1007 238.259 30.0863 238.259 30.0863C238.259 30.0863 247.068 58.3381 246.031 52.1007C244.994 45.8633 258.673 24.2158 260.884 24.2158C263.647 24.2158 266.238 30.0863 271.937 34.4892C277.636 38.8921 277.636 34.4892 279.709 30.0863C281.367 26.564 284.429 31.5539 285.753 34.4892C287.653 35.223 291.66 36.2503 292.489 34.4892C293.318 32.728 295.482 26.9065 296.461 24.2158C307.125 34.0163 304.177 40.478 298.015 52.1007C314.669 51.843 319.302 38.4338 317.704 0Z"
                fill="#213039"
            />
        </svg>
    );
};

export default BannerBottom;
