import React from "react";
import Comment from "../../components/Comment/Comment";

const DiverOnTop = (props) => {
    return (
        <svg {...props} viewBox="0 0 105 143" fill="none">
            <rect
                x="43.3203"
                y="72.8643"
                width="9.63737"
                height="3.94504"
                rx="1.97252"
                fill="#07167C"
            />
            <rect
                width="14.1911"
                height="3.855"
                rx="1.9275"
                transform="matrix(-0.023112 0.999733 -0.999707 -0.0242043 50.3223 74.334)"
                fill="#07167C"
            />
            <path
                d="M33.5706 92.8222C33.9446 85.2987 40.195 79.4395 47.7278 79.4395V79.4395C55.8376 79.4395 62.3157 86.192 61.9795 94.2948L60.6697 125.858L61.9706 156.675C62.3116 164.754 55.8538 171.49 47.7674 171.49V171.49C40.2254 171.49 33.9828 165.594 33.6226 158.061C32.509 134.769 32.3531 117.315 33.5706 92.8222Z"
                fill="#F7BA16"
            />
            <path
                d="M35.2476 157.183C38.2351 132.89 38.3408 118.287 34.8867 88.7448C50.0265 77.0506 60.327 77.7261 78.9172 88.7448C73.054 115.47 74.3647 130.457 78.9172 157.183C63.0989 172.969 53.4628 173.333 35.2476 157.183Z"
                fill="#071B81"
            />
            <path
                d="M55.5852 82.7775C55.4416 111.302 55.997 127.639 56.2495 157.071C56.2515 157.306 56.3851 157.525 56.5953 157.63C57.0166 157.841 57.5109 157.52 57.5012 157.049C56.9297 129.146 56.6229 112.502 57.0854 82.7974C57.0939 82.2536 56.5392 81.872 56.0394 82.0867C55.7651 82.2045 55.5867 82.479 55.5852 82.7775Z"
                fill="#FFFFFD"
            />
            <path
                d="M14.1354 97.6404C14.496 104.944 13.651 110.208 16.7929 119.123C20.2063 126.653 21.3428 129.519 24.7423 132.596C25.0104 132.839 25.3938 132.908 25.7246 132.766C28.7347 131.47 28.703 129.982 29.0742 125.621C29.0834 125.512 29.0751 125.399 29.0502 125.293C26.0645 112.656 23.6479 106.595 18.8603 96.764C18.5886 96.2062 17.8883 96.0333 17.4141 96.4275C16.809 96.9305 17.1345 96.6291 15.1187 96.582C14.5523 96.5687 14.107 97.0645 14.1354 97.6404Z"
                fill="#F79E85"
            />
            <path
                d="M23.5397 130.648C25.9769 115.372 26.4443 106.906 32.1002 90.4021C32.5037 89.2245 33.8729 88.6799 34.8534 89.4175C40.1087 93.371 36.6702 96.9736 36.9275 102.595C36.941 102.889 36.8996 103.195 36.8012 103.473C32.3106 116.166 37.7397 118.141 30.9761 131.584C30.7193 132.094 30.2442 132.464 29.7114 132.651C26.3738 133.826 27.0608 135.616 24.0295 132.323C23.6197 131.878 23.4433 131.253 23.5397 130.648Z"
                fill="#F79E85"
            />
            <path
                d="M88.4603 130.258C86.0231 114.982 85.5557 106.517 79.8998 90.0125C79.4963 88.8348 78.1271 88.2902 77.1466 89.0278C71.8913 92.9814 75.3298 96.5839 75.0725 102.205C75.059 102.499 75.1004 102.805 75.1988 103.083C79.6894 115.776 74.2603 117.751 81.0239 131.194C81.2807 131.704 81.7558 132.074 82.2886 132.262C85.6262 133.436 84.9392 135.226 87.9705 131.933C88.3803 131.488 88.5567 130.863 88.4603 130.258Z"
                fill="#F79E85"
            />
            <path
                d="M35.6079 43.7937C35.6079 46.6391 33.8305 48.9458 31.6379 48.9458C29.4454 48.9458 27.668 46.6391 27.668 43.7937C27.668 40.9483 29.4454 38.6416 31.6379 38.6416C33.8305 38.6416 35.6079 40.9483 35.6079 43.7937Z"
                fill="#F9AE91"
            />
            <path
                d="M86.1352 46.3689C86.1352 49.2143 84.3578 51.521 82.1653 51.521C79.9727 51.521 78.1953 49.2143 78.1953 46.3689C78.1953 43.5235 79.9727 41.2168 82.1653 41.2168C84.3578 41.2168 86.1352 43.5235 86.1352 46.3689Z"
                fill="#F9AE91"
            />
            <path
                d="M32.5159 26.3148C31.9536 32.3862 32.5853 35.5809 33.9662 40.2952C34.084 40.6973 34.4421 40.9829 34.8603 41.0093L80.0488 43.8669C80.4552 43.8926 80.8366 43.6688 81.0143 43.3024C83.7006 37.7643 85.5378 34.4726 83.8886 29.5831C83.7377 29.1357 83.8909 28.6361 84.2713 28.3564C87.9907 25.6213 90.7865 21.3998 90.1046 20.3569C89.3828 19.2529 84.282 21.3664 82.1647 23.301C83.0025 19.2746 85.7738 8.21261 84.3301 7.47663C83.0987 6.84884 75.0755 10.6153 70.4727 13.4696C69.7119 13.9414 68.6304 13.2972 68.6674 12.4028C68.8915 6.9818 67.5848 1.07403 66.6458 0.116482C65.7549 -0.791878 59.6598 3.77712 55.3767 8.09258C54.6606 8.81416 53.1862 8.17393 53.1865 7.15728C53.1877 4.06139 52.6348 1.4877 51.8486 1.2205C50.9718 0.922477 45.3243 5.44911 41.7044 9.72439C41.0493 10.4981 39.0588 10.0372 38.3128 9.35074C37.905 8.97548 37.4544 8.81173 37.0515 8.94866C36.0745 9.28076 35.2088 14.3427 35.7443 17.8202C35.8334 18.3993 35.475 18.9791 34.9123 19.1426C31.8482 20.0331 27.668 22.3163 27.668 23.301C27.668 24.0719 29.2927 24.7521 30.8588 24.7798C31.6674 24.7941 32.5905 25.5096 32.5159 26.3148Z"
                fill="#CE5425"
            />
            <path
                d="M54.3029 76.1767C44.8474 75.0725 35.3106 64.8542 33.4886 46.9889C33.1736 40.2659 34.2681 36.5243 39.1336 31.1324C41.4031 28.6174 44.5213 27.0439 47.8893 26.7298C53.5256 26.2041 61.9924 26.0318 69.0363 27.7503C70.7289 28.1633 72.3147 28.9466 73.643 30.0725C79.3147 34.8801 80.603 40.3846 79.9584 50.1532C77.8832 67.9029 63.7584 77.2809 54.3029 76.1767Z"
                fill="#F9AE91"
            />
            <path
                d="M76.2252 35.8186C64.4405 32.498 56.1342 32.2206 42.562 32.4409C40.195 32.4793 37.6616 32.7499 35.9094 34.3418C34.9077 35.2517 34.297 36.3687 33.7041 38.0266C32.7728 41.4145 32.452 43.6662 32.963 46.5083C33.3169 48.4761 34.2602 50.3018 35.5907 51.7943C37.7778 54.2478 39.3172 54.7992 41.6062 54.9549C45.0839 55.1189 46.2463 55.0799 48.0032 54.9549C49.0735 54.8933 49.8127 54.7248 50.4037 54.4178C51.8314 53.6762 52.1768 51.8357 53.1168 50.53C53.5394 49.9429 54.0493 49.5316 54.6682 49.1625C55.2205 48.833 55.8638 48.6988 56.5069 48.6988C57.5703 48.6988 58.6364 49.0715 59.2975 49.9043C59.6928 50.4023 59.9554 50.8732 60.1357 51.41C60.4244 52.2697 60.6018 53.1745 61.0474 53.9643C61.3193 54.4461 61.5813 54.8457 61.8459 55.185C63.2265 56.9553 65.7137 57.2163 67.9582 57.2618C69.3182 57.2894 70.4695 57.2941 71.589 57.2796C74.1498 57.2463 76.8515 56.8043 78.7261 55.0595C79.8164 54.0446 80.2251 53.0291 80.7407 51.6429C81.5472 48.6168 81.8681 46.517 81.7933 44.0414C81.7245 41.7615 81.0978 39.4381 79.5755 37.7394C78.6421 36.6979 77.6529 36.1582 76.2252 35.8186Z"
                fill="#071B81"
            />
            <path
                d="M73.8998 37.4557C63.4364 34.8102 56.0565 34.5853 44.0209 34.7596C41.8738 34.7907 39.5666 34.9701 37.9373 36.3687C37.1107 37.0783 36.5908 37.9497 36.0854 39.2176C35.3529 41.6088 35.0451 43.2906 35.2983 45.2165C35.5748 47.3202 36.6944 49.2441 38.257 50.6796C39.9507 52.2355 41.2538 52.6123 43.1129 52.7259C46.2056 52.8566 47.2394 52.8256 48.8018 52.7259C50.0487 52.6614 50.5267 51.6153 50.9214 50.5454C51.3875 49.2818 51.8209 47.9327 52.6548 46.8751C52.7844 46.7107 52.9231 46.5623 53.0718 46.4259C54.0418 45.5359 55.4661 45.4431 56.7782 45.5501C58.4137 45.6833 60.1104 46.1663 61.2517 47.3453C61.2761 47.3706 61.3001 47.3961 61.3234 47.4218C61.9626 48.125 62.1588 49.1272 62.5678 49.9849C62.9254 50.7349 63.2719 51.4896 63.6367 52.1659C64.629 54.0058 66.7604 54.5382 68.8505 54.5772C69.1651 54.5831 69.4424 54.5864 69.7022 54.5871C72.0337 54.5937 74.5138 54.2412 76.2579 52.694C77.1282 51.9219 77.4787 51.1366 77.9155 50.0829C78.509 48.0847 78.8068 46.5927 78.8542 45.0181C78.9288 42.5398 78.2077 39.8501 76.195 38.4022C75.55 37.9381 74.8294 37.6541 73.8998 37.4557Z"
                fill="#FCD7CB"
            />
            <ellipse
                cx="44.7623"
                cy="44.5279"
                rx="3.01035"
                ry="3.31207"
                fill="#171F61"
            />
            <ellipse
                cx="68.8463"
                cy="45.9996"
                rx="3.01035"
                ry="3.31207"
                fill="#171F61"
            />
            <path
                d="M44.7616 37.5352C43.3527 37.577 42.4625 37.8187 41.3229 38.4808C40.6878 38.8497 40.3871 39.7149 41.0087 40.1062C41.5738 40.462 42.4144 40.0971 43.0239 39.8242C43.2742 39.7122 43.5815 39.592 43.9547 39.456C44.4736 39.2668 45.047 39.2741 45.5566 39.4871C45.7196 39.5552 45.8669 39.6197 45.9997 39.6821C46.7038 40.0127 47.7417 40.5501 48.2348 39.9484C48.5826 39.524 48.3031 38.9302 47.8748 38.5871C47.1058 37.9712 46.3227 37.6647 44.7616 37.5352Z"
                fill="#BD5930"
            />
            <path
                d="M70.1701 39.2942C68.7889 39.0157 67.8654 39.0493 66.5991 39.4358C65.8946 39.6508 65.3982 40.4225 65.9091 40.944C66.3749 41.4195 67.2808 41.2545 67.9397 41.127C68.2091 41.0749 68.5355 41.0278 68.9294 40.9801C69.4794 40.9136 70.0363 41.0507 70.4819 41.3735C70.6235 41.4761 70.7508 41.5717 70.8646 41.6619C71.4725 42.1437 72.3567 42.9025 72.9786 42.4276C73.416 42.0935 73.2854 41.4541 72.9506 41.024C72.3481 40.2499 71.6589 39.774 70.1701 39.2942Z"
                fill="#BD5930"
            />
            <path
                d="M51.6145 59.6162C49.6332 60.6455 48.8935 61.5162 49.0293 64.2039"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M49.3242 61.8242C55.613 66.7075 58.8719 66.2504 64.4822 63.247"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M49.5406 84.2742C50.9542 80.5233 51.3649 78.2383 51.3689 73.823L60.4463 74.7038C60.249 78.634 60.498 80.9628 61.8385 85.4444C57.0345 87.6235 54.3472 87.6552 49.5406 84.2742Z"
                fill="#F9AE91"
                stroke="#F9AE91"
            />
            <path
                d="M35.6272 84.995C35.8765 83.1061 37.4939 81.6982 39.3991 81.6982V81.6982C42.1971 81.6982 44.0367 84.6076 42.956 87.1885C38.7204 97.304 37.6223 104.627 40.9679 114.975C41.6347 117.038 40.1595 119.235 37.9918 119.235V119.235C36.4553 119.235 35.1538 118.094 34.9781 116.568C33.651 105.038 34.0021 97.3097 35.6272 84.995Z"
                fill="#2E50CB"
            />
            <path
                d="M77.1501 85.7313C76.9008 83.8425 75.2835 82.4346 73.3782 82.4346V82.4346C70.5802 82.4346 68.7407 85.3439 69.8214 87.9248C74.057 98.0403 75.155 105.363 71.8095 115.712C71.1427 117.774 72.6179 119.971 74.7855 119.971V119.971C76.322 119.971 77.6236 118.831 77.7993 117.304C79.1263 105.774 78.7752 98.046 77.1501 85.7313Z"
                fill="#2E50CB"
            />
            <path
                d="M51.8436 133.709C58.9957 135.233 63.8663 137.4 73.2894 136.649C81.4426 135.281 84.5042 134.917 88.3505 132.421C88.6539 132.224 88.8189 131.871 88.7661 131.515C88.286 128.273 86.8394 127.922 82.719 126.445C82.6167 126.408 82.5048 126.387 82.3962 126.384C69.4165 126.029 62.9385 126.811 52.2081 128.917C51.5993 129.037 51.2527 129.669 51.5121 130.229C51.8431 130.942 51.6352 130.551 51.0728 132.487C50.9147 133.031 51.2797 133.588 51.8436 133.709Z"
                fill="#F79E85"
            />
            <path
                d="M55.8523 67.3557C57.2909 67.5355 55.8418 70.5929 55.0298 71.057C51.4053 73.1281 45.8481 70.5571 42.516 69.2651C40.2473 68.3854 32.8999 63.3145 32.5872 68.942C32.2173 75.6002 46.3347 70.9271 46.3347 77.402"
                stroke="#2E50CB"
                stroke-width="3"
                stroke-linecap="round"
            />
            <rect
                x="52.6875"
                y="63"
                width="8.3125"
                height="4.81398"
                rx="2.40699"
                fill="#071B81"
            />
            <path
                d="M15.2395 80.6932C16.3111 80.6037 17.5657 86.3024 18.663 95.831C17.6604 98.4929 16.6392 98.4451 14.0712 98.9911C11.6468 95.9768 9.64197 95.0622 7.98433 93.9472C4.92222 91.8759 0.800297 88.6866 0.991388 87.7452C1.18248 86.8038 6.79656 89.4193 6.79656 89.4193C3.92423 86.9548 0.365026 83.4788 0.581721 82.8438C0.798413 82.2087 4.791 84.8956 7.95685 87.163C5.23735 84.0048 2.7624 79.8852 2.63438 78.3535C2.50636 76.8218 6.35532 81.0263 10.1887 84.8171C7.83549 80.3392 6.20578 75.8957 7.27739 75.8061C8.349 75.7165 10.8395 80.9737 14.6032 85.9905C14.6032 85.9905 14.1679 80.7828 15.2395 80.6932Z"
                fill="#F79E85"
            />
            <path
                d="M37.4983 137.345C37.886 138.348 43.5601 136.987 52.6104 133.81C54.5667 131.745 55.1321 131.049 54.5011 128.501C49.4451 128.341 33.1127 132.806 33.5004 133.81C33.8881 134.813 35.8281 133.265 41.9851 134.458C41.9851 134.458 37.1106 136.342 37.4983 137.345Z"
                fill="#F79E85"
            />
        </svg>
    );
};

export default DiverOnTop;
