import React from "react";

const Ruins = (props) => {
    return (
        <svg {...props} viewBox="0 0 244 323" fill="none">
            <path
                d="M55.5879 56L81.0898 35L109.59 258.5C109.59 258.5 113.59 283.5 101.59 275.5C89.5898 267.5 75.0918 265 74.0898 268.5C73.0879 272 55.5879 56 55.5879 56Z"
                fill="#4D4F68"
            />
            <path
                d="M138.32 15.3002L80.7264 34.3863C80.7264 34.3863 106.958 258.206 104.409 269.898C101.861 281.591 113.299 299.591 131.832 303.591C150.365 307.591 203.365 290.091 203.365 281.591C203.365 273.091 192.147 33.2567 192.147 33.2567L138.32 15.3002Z"
                fill="#ADB5D9"
            />
            <path
                d="M143.003 26.3312L94.993 41.0567L117.195 259.981L172.237 272.396L194.919 257.649L184.078 43.0723L143.003 26.3312Z"
                fill="#99A7D4"
            />
            <path
                d="M143.003 26.3312L94.993 41.0567L117.195 259.981L172.237 272.396L194.919 257.649L184.078 43.0723L143.003 26.3312Z"
                fill="#99A7D4"
            />
            <path
                d="M143.003 26.3312L94.993 41.0567L117.195 259.981L172.237 272.396L194.919 257.649L184.078 43.0723L143.003 26.3312Z"
                fill="#99A7D4"
            />
            <path
                d="M143.003 26.3312L94.993 41.0567L117.195 259.981L172.237 272.396L194.919 257.649L184.078 43.0723L143.003 26.3312Z"
                stroke="#C8DAFF"
                stroke-width="4"
            />
            <path
                d="M182.073 43.8465L143.09 28.5L178.289 46.5034L189.16 255.004L173.59 269L192.59 257L182.073 43.8465Z"
                fill="#6274A2"
            />
            <path
                d="M163.59 230.001L159.59 223.001L173.09 223.001L173.09 230.001L163.59 230.001Z"
                fill="#656F92"
            />
            <path
                d="M155.223 47.8966L143.887 53.0929L138.435 51.8035L159.44 40.0276L161.186 58.1194L173.105 59.2813L166.043 68.4027L167.529 85.3744L140.909 79.762L145.908 75.2532L162.956 81.1253L159.697 67.1245L162.533 63.9064L158.655 62.6442L155.223 47.8966Z"
                fill="#656F92"
            />
            <path
                d="M122.165 65.1458L135.401 67.8L140.837 65.1458L115.783 58L118.383 77.1917L106.092 81.275L115.783 89.0333L118.383 107L145.092 94.75L138.71 91.2792L122.165 101.487V86.175L118.383 83.5208L122.165 81.275V65.1458Z"
                fill="#656F92"
            />
            <path
                d="M161.292 133.218L147.572 124.598L126.665 130.92L131.238 161.379H163.905L161.292 140.115H138.425L144.305 148.161H135.158L131.238 136.667H167.172L171.092 166.552L124.705 170L122.092 129.195L147.572 120L163.905 124.598L161.292 133.218Z"
                fill="#656F92"
            />
            <path
                d="M160.073 145L156.415 148.856L157.435 159.809L161.418 159.437L160.073 145Z"
                fill="#656F92"
            />
            <path
                d="M125.092 189.829V180H173.092L148.386 189.829L150.504 206.414H173.092V215.014H158.268L134.974 223V210.1H144.151V195.357L139.209 187.986L125.092 189.829Z"
                fill="#656F92"
            />
            <path
                d="M143.109 253L136.092 236L149.488 239.091H173.092V249.394L156.506 245.788L143.109 253Z"
                fill="#656F92"
            />
            <path
                d="M76.9756 215L56.5879 223.622V247.5L64.3022 256.122L62.0981 272.704L66.5 280.5C77.1113 279.395 83.4477 278.083 87.9961 280L85.792 254.133L78.6287 247.5L76.9756 215Z"
                fill="#8C94C3"
            />
            <path
                d="M25.1806 248.163V222.296H58.7928L60.4459 245.51L66.5071 254.133V270.714L69.8132 280C-15.7146 293.078 -2.42168 287.279 6.9969 280L25.1806 270.714L21.8745 248.163H25.1806Z"
                fill="#4D4F68"
            />
            <path
                d="M37.3021 217.653L25.1797 222.296L58.7919 223.622L76.9756 215L37.3021 217.653Z"
                fill="#AABDDB"
            />
        </svg>
    );
};

export default Ruins;
