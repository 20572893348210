import "./animations.css";

export const DiverJumping = (props) => {
    return (
        <svg {...props} viewBox="0 0 213 418" fill="none">
            <rect
                x="88.2852"
                y="99"
                width="13.3516"
                height="5.36"
                rx="2.68"
                fill="#07167C"
            />
            <rect
                width="19.2812"
                height="5.34067"
                rx="2.67034"
                transform="matrix(-0.0235666 0.999722 -0.999718 -0.0237375 97.9863 100.997)"
                fill="#07167C"
            />
            <path
                d="M74.7599 126.456C75.2848 116.041 83.9424 107.933 94.3705 107.933V107.933C105.614 107.933 114.593 117.3 114.117 128.533L112.32 171L114.105 212.456C114.587 223.656 105.637 233 94.4265 233V233C83.9852 233 75.3387 224.843 74.8326 214.414C73.3101 183.045 73.0978 159.436 74.7599 126.456Z"
                fill="#F7BA16"
            />
            <path
                d="M64.2115 413.764C61.8608 411.675 65.6974 366.421 85.1892 348.149C91.6001 346.659 93.2507 348.577 95.0567 353.609C104.243 378.806 109.187 414.545 106.928 415.3C104.668 416.056 99.0962 412.266 93.068 408.911C90.901 411.99 88.8227 415.829 85.7503 416.502C82.6778 417.175 80.0377 412.247 76.484 408.315C71.6127 411.783 66.5621 415.853 64.2115 413.764Z"
                fill="#FFFFFD"
            />
            <path
                d="M166.784 409.885C168.95 407.606 161.303 362.837 140.338 346.277C133.824 345.334 132.341 347.385 130.967 352.551C123.942 378.434 122.035 414.463 124.35 415.025C126.665 415.587 131.897 411.34 137.62 407.488C140.04 410.373 142.435 414.022 145.553 414.434C148.672 414.845 150.886 409.711 154.095 405.492C159.242 408.537 164.618 412.165 166.784 409.885Z"
                fill="#FFFFFD"
            />
            <path
                d="M75.8974 279.271C73.2924 251.381 73.4844 235.679 78.0186 207.507C94.4625 212.152 103.037 215.54 109.796 231.801C105.22 269.194 100.46 282.803 89.0951 294.773C82.1577 296.657 78.3833 297.178 75.8974 279.271Z"
                fill="#071B81"
            />
            <path
                d="M143.775 276.86C144.015 248.85 142.497 233.22 135.599 205.532C119.606 211.55 111.348 215.65 105.988 232.424C113.707 269.296 119.599 282.455 131.935 293.422C139.006 294.713 142.811 294.913 143.775 276.86Z"
                fill="#071B81"
            />
            <path
                d="M84.0357 356.89C84.0443 352.61 84.7589 348.418 82.9473 343.438L91.9263 343.761C90.3237 348.109 91.8731 350.437 91.4447 357.156C91.0162 363.875 83.5156 359.265 84.0357 356.89Z"
                fill="#F79E85"
            />
            <path
                d="M142.224 354.889C141.854 350.625 140.787 346.508 142.172 341.394L133.252 342.474C135.216 346.671 133.869 349.122 134.864 355.781C135.858 362.439 142.943 357.212 142.224 354.889Z"
                fill="#F79E85"
            />
            <path
                d="M82.9304 343.938C77.5688 321.921 77.1216 315.089 80.0051 294.254C86.2751 290.271 84.5932 288.749 91.3613 292.694C99.6149 316.506 99.8782 325.951 91.4273 344.244C88.8823 345.316 85.549 345.198 82.9304 343.938Z"
                fill="#071B81"
            />
            <path
                d="M142.231 341.891C145.713 319.5 145.581 312.654 140.948 292.137C134.364 288.698 135.911 287.039 129.501 291.542C123.288 315.966 123.824 325.4 133.79 342.913C136.416 343.766 139.728 343.367 142.231 341.891Z"
                fill="#071B81"
            />
            <path
                d="M75.6511 396.555C76.1986 386.362 79.3617 371.249 80.1288 369.97C80.8958 368.691 82.0036 369.995 82.0036 369.995C79.0813 380.7 78.8223 386.738 78.2758 396.59C77.3669 397.529 76.8223 397.709 75.6511 396.555Z"
                fill="#0E196C"
            />
            <path
                d="M153.93 393.704C152.523 383.594 148.095 368.802 147.222 367.592C146.35 366.383 145.356 367.775 145.356 367.775C149.172 378.195 149.941 384.19 151.317 393.96C152.302 394.819 152.86 394.953 153.93 393.704Z"
                fill="#0E196C"
            />
            <path
                d="M92.0548 397.347C92.6699 387.158 91.2459 371.783 90.6293 370.425C90.0127 369.067 88.7638 370.236 88.7638 370.236C90.4498 381.204 90.0205 387.233 89.4431 397.083C90.2394 398.119 90.76 398.36 92.0548 397.347Z"
                fill="#0E196C"
            />
            <path
                d="M137.653 395.879C136.179 385.778 136.299 370.338 136.799 368.933C137.298 367.527 138.642 368.587 138.642 368.587C137.888 379.658 138.825 385.629 140.233 395.395C139.527 396.495 139.028 396.779 137.653 395.879Z"
                fill="#0E196C"
            />
            <path
                d="M77.0996 213.559C81.2384 180.554 81.3849 160.713 76.5996 120.575C97.5744 104.686 111.845 105.604 137.6 120.575C129.477 156.886 131.293 177.248 137.6 213.559C115.685 235.007 102.335 235.502 77.0996 213.559Z"
                fill="#071B81"
            />
            <path
                d="M133.195 212.168C113.899 215.526 102.641 215.688 82.8491 212.482C82.398 212.409 81.9586 212.691 81.8462 213.134C81.7155 213.649 82.0737 214.162 82.6012 214.224C102.373 216.551 113.975 216.627 133.514 214.239C134.095 214.168 134.496 213.614 134.374 213.041L134.354 212.946C134.243 212.419 133.726 212.076 133.195 212.168Z"
                fill="#2E50CB"
            />
            <path
                d="M105.277 112.459C105.078 151.214 105.847 173.41 106.197 213.392C106.2 213.72 106.388 214.025 106.682 214.17C107.266 214.456 107.945 214.011 107.931 213.362C107.139 175.447 106.714 152.832 107.356 112.453C107.367 111.731 106.633 111.222 105.968 111.502L105.889 111.535C105.52 111.691 105.279 112.058 105.277 112.459Z"
                fill="#FFFFFD"
            />
            <path
                d="M186.045 48.802C179.61 56.5738 175.953 61.5834 170.711 73.5002C167.069 84.272 165.541 88.139 166.15 94.5135C166.184 94.8748 166.42 95.1886 166.759 95.3185C171.189 97.0165 172.518 95.5236 177.076 91.4894C177.164 91.4111 177.242 91.3142 177.297 91.2101C184.882 77.1022 188.475 68.44 193.81 53.0337C194.017 52.4348 193.622 51.7967 192.993 51.7196C190.716 51.4402 190.377 51.2944 187.486 48.6986C187.063 48.319 186.408 48.3644 186.045 48.802Z"
                fill="#F79E85"
            />
            <path
                d="M22.8966 44.0649C29.6294 52.0016 35.6055 56.443 41.0324 68.7328C44.8346 79.8665 46.4208 83.8521 45.8702 90.4771C45.8403 90.8367 45.6118 91.1523 45.2775 91.2881C40.8073 93.1044 39.4522 91.5678 34.7875 87.424C34.7072 87.3526 34.6357 87.2651 34.582 87.172C25.386 71.2313 22.0523 62.6931 17.4029 47.7005C17.2208 47.1133 17.6088 46.4968 18.2208 46.4391C20.0348 46.2681 18.8402 46.5921 21.4413 43.9927C21.8502 43.5841 22.5227 43.624 22.8966 44.0649Z"
                fill="#F79E85"
            />
            <path
                d="M37.7497 91.7407C51.8078 108.157 58.2905 117.718 77.471 133.35C78.3285 134.049 79.5986 133.915 80.2934 133.054C84.1059 128.329 84.9599 126.872 82.7357 116.335C82.6397 115.881 82.3777 115.463 82.0156 115.172C67.5708 103.567 65.4249 94.3994 47.4048 83.4857C46.9237 83.1943 46.3289 83.1245 45.7855 83.2697C40.4576 84.693 38.774 83.3114 37.3098 90.0459C37.1799 90.6433 37.352 91.2763 37.7497 91.7407Z"
                fill="#F79E85"
            />
            <path
                d="M174.817 94.3451C161.099 110.922 154.773 120.578 136.053 136.405C135.115 137.198 133.685 136.957 133.115 135.869C128.214 126.504 131.624 122.861 135.139 115.671C135.268 115.408 135.46 115.167 135.686 114.98C150.07 103.138 148.807 99.248 166.633 88.128C167.11 87.8303 167.708 87.7516 168.257 87.8752C173.459 89.0464 173.775 86.0865 175.234 92.6404C175.367 93.2372 175.207 93.874 174.817 94.3451Z"
                fill="#F79E85"
            />
            <path
                d="M176.483 30.4486C174.928 31.4319 178.377 39.3686 185.52 51.6237C189.316 53.7183 190.677 52.6255 194.703 50.6567C195.3 44.671 197.226 41.5662 198.493 38.5797C200.823 33.0491 203.594 25.1353 202.477 24.2286C201.359 23.3219 196.015 32.0797 196.015 32.0797C197.726 26.2821 199.464 18.6044 198.589 18.0818C197.715 17.5593 194.665 24.7543 192.371 30.6195C193.242 24.1659 192.905 16.8365 191.69 14.9157C190.475 12.9948 189.001 21.8184 187.172 30.1426C186.342 22.5178 184.548 15.6678 182.993 16.651C181.438 17.6342 182.787 26.3095 182.167 35.9963C182.167 35.9963 178.037 29.4654 176.483 30.4486Z"
                fill="#F79E85"
            />
            <path
                d="M35.7342 27.4486C37.289 28.4319 33.8395 36.3686 26.6964 48.6237C22.9009 50.7183 21.5402 49.6255 17.5134 47.6567C16.9169 41.671 14.9908 38.5662 13.7239 35.5797C11.3943 30.0491 8.6226 22.1353 9.74014 21.2286C10.8577 20.3219 16.202 29.0797 16.202 29.0797C14.4904 23.2821 12.7527 15.6044 13.6273 15.0818C14.5019 14.5593 17.552 21.7543 19.8461 27.6195C18.9745 21.1659 19.3121 13.8365 20.5268 11.9157C21.7415 9.99482 23.2162 18.8184 25.045 27.1426C25.8752 19.5178 27.6691 12.6678 29.2239 13.651C30.7786 14.6342 29.4295 23.3095 30.0493 32.9963C30.0493 32.9963 34.1794 26.4654 35.7342 27.4486Z"
                fill="#F79E85"
            />
            <path
                d="M77.5996 59.5C77.5996 63.366 75.1372 66.5 72.0996 66.5C69.062 66.5 66.5996 63.366 66.5996 59.5C66.5996 55.634 69.062 52.5 72.0996 52.5C75.1372 52.5 77.5996 55.634 77.5996 59.5Z"
                fill="#F9AE91"
            />
            <path
                d="M147.6 63C147.6 66.866 145.137 70 142.1 70C139.062 70 136.6 66.866 136.6 63C136.6 59.134 139.062 56 142.1 56C145.137 56 147.6 59.134 147.6 63Z"
                fill="#F9AE91"
            />
            <path
                d="M73.3853 35.0518C72.4777 43.8687 73.3804 48.2718 75.3986 54.9931C75.5187 55.393 75.8759 55.6754 76.2927 55.7012L139.434 59.617C139.837 59.642 140.216 59.42 140.395 59.0578C144.226 51.2967 146.864 46.7849 144.394 39.9278C144.232 39.4781 144.389 38.9714 144.78 38.6963C150.051 34.9877 154.059 29.0969 153.1 27.6583C152.1 26.1583 145.033 29.0298 142.1 31.6583C143.26 26.1877 147.1 11.1582 145.1 10.1583C143.319 9.26819 131.293 14.8792 125.097 18.7993C124.343 19.2762 123.292 18.6402 123.352 17.7502C123.86 10.1531 121.954 1.51241 120.6 0.15826C119.295 -1.14592 109.94 5.86025 104.003 11.9884C103.299 12.7161 101.887 12.0914 101.926 11.0791C102.113 6.29229 101.304 2.05966 100.1 1.65826C98.8102 1.22845 90.0738 8.1844 85.1549 14.2779C84.5211 15.0632 82.7084 14.6407 82.1959 13.7714C81.4802 12.5573 80.4612 11.8711 79.5996 12.1583C78.2094 12.6217 76.982 19.8631 77.8529 24.5897C77.9597 25.1693 77.6003 25.7507 77.0303 25.9004C72.7596 27.0221 66.5996 30.2786 66.5996 31.6583C66.5996 32.8274 69.4038 33.8429 71.7695 33.6436C72.5727 33.576 73.4678 34.25 73.3853 35.0518Z"
                fill="#CE5425"
            />
            <path
                d="M103.5 103.5C90.4003 102 77.1879 88.1161 74.6637 63.8431C74.1989 54.1157 75.9487 48.9822 83.8643 40.8424C86.1925 38.4483 89.2428 36.8958 92.5617 36.5256C100.487 35.6416 114.076 35.1226 124.908 37.9536C126.58 38.3907 128.152 39.1613 129.512 40.2281C138.033 46.9133 139.965 54.4406 139.043 68.1425C136.168 92.2584 116.6 105 103.5 103.5Z"
                fill="#F9AE91"
            />
            <path
                d="M133.871 48.6662C117.13 44.0401 105.456 43.7613 85.7875 44.1012C83.4613 44.1413 81.0757 44.5025 79.1416 45.7955C77.0453 47.1969 75.9845 48.8622 74.962 51.6662C73.5591 56.6712 73.1561 59.8511 74.1474 64.2215C74.5947 66.1934 75.5538 68.0191 76.8631 69.5599C80.2853 73.5876 82.4957 74.4384 85.9096 74.6662C90.7276 74.8889 92.338 74.836 94.772 74.6662C96.2287 74.5839 97.2427 74.3615 98.0542 73.9583C100.06 72.9615 100.552 70.3873 101.899 68.597C102.475 67.8305 103.168 67.2868 104.003 66.7977C104.771 66.3483 105.661 66.1662 106.55 66.1662C108.027 66.1662 109.51 66.6742 110.436 67.8244C110.963 68.4792 111.318 69.1006 111.564 69.8036C111.983 70.9977 112.232 72.2625 112.865 73.358C113.519 74.4883 114.133 75.2833 114.796 75.8979C116.457 77.4359 118.873 77.714 121.136 77.7713C124.283 77.8511 126.6 77.8534 129.01 77.7959C131.528 77.7358 134.076 77.2086 136.137 75.761C138.595 74.0346 139.249 72.4808 140.127 70.1662C141.373 65.5817 141.782 62.562 141.528 58.6545C141.377 56.3432 140.678 54.0621 139.284 52.2117C137.744 50.1657 136.245 49.2201 133.871 48.6662Z"
                fill="#071B81"
            />
            <path
                d="M130.649 50.8899C115.776 47.2023 105.401 46.9764 87.9433 47.2463C85.834 47.2789 83.6686 47.557 81.8723 48.663C80.0774 49.7682 79.1525 51.0887 78.2604 53.2838C77.1053 56.9814 76.7125 59.4306 77.3601 62.5412C77.797 64.639 78.9201 66.539 80.4317 68.0578C83.2084 70.8476 85.123 71.4649 87.9962 71.637C92.2809 71.8147 93.713 71.7725 95.8776 71.637C97.5824 71.5505 98.2497 70.1652 98.7925 68.7318C99.457 66.9769 100.061 65.0898 101.254 63.641C101.421 63.4381 101.598 63.2532 101.788 63.0822C103.139 61.8631 105.107 61.7419 106.921 61.8869C109.193 62.0685 111.559 62.7173 113.149 64.3505C113.164 64.3655 113.178 64.3805 113.192 64.3956C114.105 65.3594 114.39 66.7619 114.97 67.956C115.658 69.3724 116.318 70.7982 117.057 71.9301C118.181 73.6495 120.328 74.0692 122.381 74.1233C124.156 74.1701 125.131 74.1769 126.254 74.1513C128.545 74.0989 130.871 73.6714 132.795 72.4265C134.873 71.0822 135.447 69.8555 136.212 68.0461C137.197 64.7928 137.594 62.5271 137.511 59.8723C137.432 57.3579 136.619 54.8192 134.843 53.0382C133.673 51.8659 132.441 51.2652 130.649 50.8899Z"
                fill="#FCD7CB"
            />
            <ellipse
                cx="90.2819"
                cy="60.4995"
                rx="4.17055"
                ry="4.5"
                fill="#171F61"
            />
            <ellipse
                cx="123.647"
                cy="62.4995"
                rx="4.17055"
                ry="4.5"
                fill="#171F61"
            />
            <path
                d="M90.2812 50.9995C88.337 51.0562 87.1056 51.3822 85.5357 52.2737C84.649 52.7773 84.2272 53.9753 85.0995 54.5035C85.8755 54.9734 87.0224 54.4858 87.8521 54.1191C88.2048 53.9632 88.6405 53.796 89.1726 53.6059C89.8864 53.351 90.6725 53.3608 91.374 53.648C91.6113 53.7451 91.8246 53.8369 92.0159 53.9255C92.9775 54.3705 94.3935 55.0953 95.0827 54.2905C95.5801 53.7097 95.1814 52.8877 94.5789 52.4168C93.5184 51.588 92.4336 51.1746 90.2812 50.9995Z"
                fill="#BD5930"
            />
            <path
                d="M125.484 53.389C123.577 53.012 122.3 53.056 120.555 53.576C119.573 53.8687 118.876 54.9357 119.594 55.6435C120.236 56.2763 121.474 56.0558 122.373 55.8832C122.752 55.8104 123.214 55.7449 123.774 55.6786C124.531 55.5889 125.296 55.7735 125.909 56.2094C126.115 56.355 126.298 56.4903 126.461 56.6177C127.292 57.2659 128.499 58.2878 129.364 57.6548C129.984 57.2003 129.796 56.3162 129.326 55.7259C128.493 54.682 127.538 54.0382 125.484 53.389Z"
                fill="#BD5930"
            />
            <path
                d="M99.7737 80.9999C97.0289 82.3984 96.0041 83.5813 96.1921 87.233"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M96.5996 84C105.312 90.6348 109.827 90.0137 117.6 85.9331"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M96.8994 114.5C98.8578 109.404 99.4268 106.299 99.4323 100.3L112.008 101.497C111.735 106.837 112.08 110.001 113.937 116.09C107.281 119.051 103.558 119.094 96.8994 114.5Z"
                fill="#F9AE91"
                stroke="#F9AE91"
            />
            <path
                d="M77.6194 115.533C77.9689 112.934 80.197 111 82.8195 111V111C86.6961 111 89.232 115.046 87.7143 118.614C81.9179 132.237 80.417 142.146 84.9848 156.088C85.9202 158.943 83.8839 162 80.8795 162V162C78.7628 162 76.968 160.431 76.7217 158.329C74.8918 142.706 75.3765 132.216 77.6194 115.533Z"
                fill="#2E50CB"
            />
            <path
                d="M135.16 116.533C134.81 113.934 132.582 112 129.96 112V112C126.083 112 123.547 116.046 125.065 119.614C130.861 133.237 132.362 143.146 127.795 157.088C126.859 159.943 128.895 163 131.9 163V163C134.017 163 135.811 161.431 136.058 159.329C137.887 143.706 137.403 133.216 135.16 116.533Z"
                fill="#2E50CB"
            />
        </svg>
    );
};
