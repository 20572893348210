import React from "react";

const DiverMeditating = (props) => {
    return (
        <svg {...props} viewBox="0 0 165 313" fill="none">
            <rect
                x="59.2363"
                y="99"
                width="13.3516"
                height="5.36"
                rx="2.68"
                fill="#07167C"
            />
            <rect
                width="19.2812"
                height="5.34067"
                rx="2.67034"
                transform="matrix(-0.0235666 0.999722 -0.999718 -0.0237375 68.9375 100.997)"
                fill="#07167C"
            />
            <path
                d="M45.7111 126.456C46.236 116.041 54.8935 107.934 65.3217 107.934C76.5651 107.934 85.5437 117.3 85.0684 128.533L83.2715 171L85.0558 212.457C85.5379 223.657 76.588 233 65.3777 233C54.9364 233 46.2899 224.843 45.7837 214.414C44.2612 183.046 44.0489 159.436 45.7111 126.456Z"
                fill="#F7BA16"
            />
            <path
                d="M142.823 223.479C117.067 212.467 102.059 207.847 73.8502 203.547C73.2426 220.623 69.3192 230.359 82.7331 241.767C119.733 248.848 138.673 247.944 153.545 240.786C157.46 234.757 159.111 231.323 142.823 223.479Z"
                fill="#071B81"
            />
            <path
                d="M17.5412 229.938C41.807 215.946 56.1601 209.576 83.6582 201.955C86.2897 218.838 91.3417 228.039 79.3777 240.96C43.4804 252.386 24.5679 253.738 8.95058 248.396C4.3469 242.875 2.29988 239.661 17.5412 229.938Z"
                fill="#071B81"
            />
            <path
                d="M48.0508 213.559C52.1896 180.554 52.336 160.713 47.5508 120.575C68.5255 104.686 82.7959 105.604 108.551 120.575C100.428 156.886 102.244 177.248 108.551 213.559C86.636 235.007 73.2861 235.502 48.0508 213.559Z"
                fill="#071B81"
            />
            <path
                d="M104.146 212.168C84.8505 215.526 73.5917 215.688 53.8002 212.482C53.3491 212.409 52.9098 212.691 52.7973 213.134C52.6666 213.649 53.0248 214.162 53.5524 214.224C73.3242 216.551 84.9265 216.627 104.465 214.239C105.046 214.168 105.447 213.614 105.326 213.041L105.306 212.946C105.194 212.419 104.678 212.076 104.146 212.168Z"
                fill="#2E50CB"
            />
            <path
                d="M76.2282 112.459C76.0292 151.214 76.7985 173.41 77.1483 213.392C77.1512 213.72 77.339 214.025 77.6336 214.17C78.2168 214.456 78.8958 214.011 78.8823 213.362C78.0905 175.447 77.6656 152.832 78.3072 112.453C78.3187 111.731 77.5846 111.222 76.9189 111.502L76.84 111.535C76.471 111.691 76.2303 112.058 76.2282 112.459Z"
                fill="#FFFFFD"
            />
            <path
                d="M135.342 217.138C135.346 207.048 134.971 200.857 131.414 188.334C127.353 177.713 126.066 173.759 121.534 169.235C121.277 168.979 120.895 168.887 120.551 169.003C116.055 170.519 115.983 172.516 115.043 178.53C115.024 178.647 115.027 178.771 115.05 178.886C118.199 194.591 120.952 203.556 126.662 218.827C126.884 219.421 127.595 219.66 128.129 219.319C130.061 218.083 130.416 217.979 134.298 218.136C134.866 218.159 135.342 217.707 135.342 217.138Z"
                fill="#F79E85"
            />
            <path
                d="M21.4724 217.729C20.0265 207.743 19.5129 201.562 21.2445 188.659C23.7458 177.567 24.4546 173.469 28.2944 168.345C28.5121 168.054 28.8766 167.909 29.2336 167.975C33.9002 168.833 34.2571 170.799 36.047 176.617C36.0818 176.73 36.0971 176.852 36.0906 176.97C35.2176 192.964 33.7741 202.23 30.3045 218.16C30.1696 218.78 29.4998 219.118 28.9226 218.857C26.8336 217.91 26.4682 217.857 22.6483 218.568C22.0897 218.672 21.5538 218.292 21.4724 217.729Z"
                fill="#F79E85"
            />
            <path
                d="M127.191 177.47C121.698 155.436 118.503 142.621 107.771 118.835C107.319 117.832 106.122 117.361 105.167 117.907C100.347 120.666 104.083 122.614 101.411 133.524C101.3 133.977 101.345 134.472 101.536 134.896C109.509 152.582 102.606 161.802 114.263 180.552C114.557 181.026 115.054 181.357 115.6 181.472C121.321 182.685 122.233 184.805 126.817 179.19C127.206 178.713 127.34 178.067 127.191 177.47Z"
                fill="#F79E85"
            />
            <path
                d="M24.9138 171.534C32.1955 151.184 34.8438 139.94 47.1909 118.497C47.7429 117.539 48.9911 117.194 49.8819 117.85C54.2416 121.061 51.3617 123.158 52.8496 133.781C52.9141 134.241 52.8183 134.727 52.5858 135.129C43.3608 151.086 49.6779 159.217 36.8778 175.801C36.5341 176.246 36.001 176.525 35.4416 176.583C29.9564 177.153 28.876 179.044 25.1071 173.274C24.7727 172.762 24.7078 172.109 24.9138 171.534Z"
                fill="#F79E85"
            />
            <path
                d="M48.5508 59.5C48.5508 63.366 46.0883 66.5 43.0508 66.5C40.0132 66.5 37.5508 63.366 37.5508 59.5C37.5508 55.634 40.0132 52.5 43.0508 52.5C46.0883 52.5 48.5508 55.634 48.5508 59.5Z"
                fill="#F9AE91"
            />
            <path
                d="M118.551 63C118.551 66.866 116.088 70 113.051 70C110.013 70 107.551 66.866 107.551 63C107.551 59.134 110.013 56 113.051 56C116.088 56 118.551 59.134 118.551 63Z"
                fill="#F9AE91"
            />
            <path
                d="M44.3364 35.0518C43.4289 43.8687 44.3316 48.2718 46.3498 54.9931C46.4699 55.393 46.8271 55.6754 47.2438 55.7012L110.385 59.617C110.788 59.642 111.167 59.42 111.346 59.0578C115.177 51.2967 117.815 46.7849 115.345 39.9278C115.184 39.4781 115.341 38.9714 115.732 38.6963C121.002 34.9877 125.01 29.0969 124.051 27.6583C123.051 26.1583 115.984 29.0298 113.051 31.6583C114.212 26.1877 118.051 11.1582 116.051 10.1583C114.271 9.26819 102.244 14.8792 96.0485 18.7993C95.2947 19.2762 94.2432 18.6402 94.3027 17.7502C94.8109 10.1531 92.9049 1.51241 91.5508 0.15826C90.2466 -1.14592 80.8909 5.86025 74.9546 11.9884C74.2497 12.7161 72.8381 12.0914 72.8776 11.0791C73.0643 6.29229 72.255 2.05966 71.0508 1.65826C69.7613 1.22845 61.025 8.1844 56.1061 14.2779C55.4722 15.0632 53.6596 14.6407 53.1471 13.7714C52.4314 12.5573 51.4123 11.8711 50.5508 12.1583C49.1606 12.6217 47.9332 19.8631 48.8041 24.5897C48.9109 25.1693 48.5515 25.7507 47.9815 25.9004C43.7107 27.0221 37.5508 30.2786 37.5508 31.6583C37.5508 32.8274 40.3549 33.8429 42.7206 33.6436C43.5239 33.576 44.419 34.25 44.3364 35.0518Z"
                fill="#CE5425"
            />
            <path
                d="M74.4511 103.5C61.3514 102 48.1391 88.1161 45.6149 63.8431C45.1501 54.1157 46.8999 48.9822 54.8155 40.8424C57.1437 38.4483 60.194 36.8958 63.5129 36.5256C71.4385 35.6416 85.0276 35.1226 95.8594 37.9536C97.5316 38.3907 99.1031 39.1613 100.463 40.2281C108.984 46.9133 110.916 54.4406 109.994 68.1425C107.119 92.2584 87.5508 105 74.4511 103.5Z"
                fill="#F9AE91"
            />
            <path
                d="M104.822 48.6657C88.0811 44.0396 76.4068 43.7608 56.7387 44.1007C54.4125 44.1409 52.0269 44.502 50.0927 45.7951C47.9965 47.1964 46.9357 48.8617 45.9131 51.6657C44.5103 56.6707 44.1073 59.8506 45.0986 64.221C45.5458 66.1929 46.505 68.0186 47.8143 69.5595C51.2365 73.5871 53.4469 74.4379 56.8608 74.6657C61.6788 74.8884 63.2892 74.8356 65.7232 74.6657C67.1798 74.5834 68.1939 74.361 69.0053 73.9578C71.0115 72.961 71.5036 70.3868 72.8501 68.5965C73.4266 67.83 74.1187 67.2863 74.9543 66.7972C75.7219 66.3479 76.612 66.1657 77.5015 66.1657C78.978 66.1657 80.461 66.6737 81.3867 67.8239C81.9138 68.4787 82.2689 69.1001 82.5156 69.8031C82.9346 70.9972 83.183 72.262 83.8166 73.3575C84.4703 74.4878 85.0839 75.2828 85.7476 75.8974C87.4083 77.4354 89.824 77.7135 92.0868 77.7708C95.2337 77.8506 97.5512 77.8529 99.9616 77.7954C102.479 77.7353 105.027 77.2081 107.088 75.7605C109.546 74.0341 110.2 72.4803 111.078 70.1657C112.324 65.5812 112.733 62.5615 112.479 58.654C112.328 56.3427 111.629 54.0616 110.236 52.2112C108.695 50.1652 107.196 49.2196 104.822 48.6657Z"
                fill="#071B81"
            />
            <path
                d="M101.6 50.8899C86.7276 47.2023 76.3519 46.9764 58.8945 47.2463C56.7852 47.2789 54.6198 47.557 52.8235 48.663C51.0286 49.7682 50.1037 51.0887 49.2115 53.2838C48.0565 56.9814 47.6636 59.4306 48.3113 62.5412C48.7481 64.639 49.8712 66.539 51.3829 68.0578C54.1595 70.8476 56.0741 71.4649 58.9474 71.637C63.232 71.8147 64.6642 71.7725 66.8288 71.637C68.5336 71.5505 69.2009 70.1652 69.7437 68.7318C70.4081 66.9769 71.0124 65.0898 72.2048 63.641C72.3718 63.4381 72.5497 63.2532 72.7391 63.0822C74.0904 61.8631 76.0581 61.7419 77.8723 61.8869C80.1442 62.0685 82.5105 62.7173 84.1003 64.3505C84.1149 64.3655 84.1293 64.3805 84.1437 64.3956C85.0565 65.3594 85.3412 66.7619 85.9212 67.956C86.6091 69.3724 87.2687 70.7982 88.0085 71.9301C89.1321 73.6495 91.2788 74.0692 93.332 74.1233C95.1069 74.1701 96.0821 74.1769 97.2047 74.1513C99.496 74.0989 101.822 73.6714 103.747 72.4265C105.825 71.0822 106.398 69.8555 107.163 68.0461C108.148 64.7928 108.545 62.5271 108.462 59.8723C108.383 57.3579 107.571 54.8192 105.794 53.0382C104.624 51.8659 103.393 51.2652 101.6 50.8899Z"
                fill="#FCD7CB"
            />
            <path
                d="M61.2324 50.999C59.2881 51.0557 58.0568 51.3817 56.4869 52.2732C55.6001 52.7768 55.1784 53.9748 56.0507 54.503C56.8266 54.9729 57.9736 54.4853 58.8033 54.1186C59.156 53.9627 59.5917 53.7955 60.1238 53.6054C60.8376 53.3505 61.6237 53.3603 62.3251 53.6475C62.5625 53.7447 62.7757 53.8364 62.9671 53.925C63.9286 54.37 65.3447 55.0948 66.0339 54.29C66.5313 53.7092 66.1326 52.8872 65.5301 52.4163C64.4696 51.5875 63.3847 51.1742 61.2324 50.999Z"
                fill="#BD5930"
            />
            <path
                d="M96.4354 53.3885C94.5286 53.0115 93.2514 53.0555 91.5065 53.5755C90.5239 53.8683 89.8268 54.9352 90.5451 55.643C91.1874 56.2758 92.4252 56.0553 93.3244 55.8827C93.7036 55.81 94.1655 55.7444 94.7253 55.6781C95.4825 55.5884 96.2467 55.773 96.8606 56.2089C97.0658 56.3545 97.2492 56.4898 97.4126 56.6172C98.2434 57.2654 99.4505 58.2874 100.315 57.6543C100.935 57.1998 100.747 56.3157 100.277 55.7254C99.4446 54.6815 98.4892 54.0377 96.4354 53.3885Z"
                fill="#BD5930"
            />
            <path
                d="M94.142 68.0388C92.8922 67.8752 92.033 67.4669 90.9256 66.5518C90.3551 66.0804 90.1369 65.1666 90.6601 64.6626C91.2885 64.0571 92.3423 64.6636 93.1106 65.1087C93.2984 65.2176 93.5125 65.3332 93.7552 65.4586C94.338 65.7598 95.0278 65.7771 95.606 65.4888C95.6686 65.4576 95.7289 65.427 95.7868 65.3968C96.6627 64.9404 97.9771 64.2745 98.3832 65.1915C98.5974 65.6751 98.3984 66.3078 98.0347 66.6796C97.258 67.4737 96.5185 67.8877 95.13 68.0458C94.8027 68.0831 94.4701 68.0817 94.142 68.0388Z"
                fill="#171F61"
            />
            <path
                d="M60.142 65.0388C58.8922 64.8752 58.033 64.4669 56.9256 63.5518C56.3551 63.0804 56.1369 62.1666 56.6601 61.6626C57.2885 61.0571 58.3423 61.6636 59.1106 62.1087C59.2984 62.2176 59.5125 62.3332 59.7552 62.4586C60.338 62.7598 61.0278 62.7771 61.606 62.4888C61.6686 62.4576 61.7289 62.427 61.7868 62.3968C62.6627 61.9404 63.9771 61.2745 64.3832 62.1915C64.5974 62.6751 64.3984 63.3078 64.0347 63.6796C63.258 64.4737 62.5185 64.8877 61.13 65.0458C60.8027 65.0831 60.4701 65.0817 60.142 65.0388Z"
                fill="#171F61"
            />
            <path
                d="M70.9551 89C75.9337 90.327 78.5136 90.2027 82.9551 89.3866"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M67.8506 114.5C69.809 109.404 70.378 106.299 70.3835 100.3L82.9593 101.497C82.686 106.837 83.031 110.001 84.8881 116.09C78.2326 119.051 74.5096 119.094 67.8506 114.5Z"
                fill="#F9AE91"
                stroke="#F9AE91"
            />
            <path
                d="M48.5706 115.533C48.92 112.934 51.1482 111 53.7707 111C57.6473 111 60.1831 115.046 58.6654 118.614C52.869 132.237 51.3681 142.146 55.936 156.088C56.8714 158.943 54.835 162 51.8307 162C49.7139 162 47.9192 160.431 47.6729 158.329C45.843 142.706 46.3276 132.216 48.5706 115.533Z"
                fill="#2E50CB"
            />
            <path
                d="M106.111 116.533C105.762 113.934 103.533 112 100.911 112C97.0344 112 94.4985 116.046 96.0162 119.614C101.813 133.237 103.314 143.146 98.7457 157.088C97.8103 159.943 99.8466 163 102.851 163C104.968 163 106.762 161.431 107.009 159.329C108.839 143.706 108.354 133.216 106.111 116.533Z"
                fill="#2E50CB"
            />
            <path
                d="M97.6242 232.863C119.641 227.503 126.474 227.056 147.309 229.941C151.291 236.212 152.814 234.53 148.868 241.298C125.055 249.55 115.61 249.812 97.318 241.36C96.2459 238.815 96.3642 235.482 97.6242 232.863Z"
                fill="#071B81"
            />
            <path
                d="M26.1909 265.388C25.8649 262.26 59.2281 231.445 85.9432 231.108C91.6804 234.333 91.6047 236.863 89.5429 241.796C79.3244 266.592 58.8619 296.307 56.6845 295.34C54.507 294.374 52.951 287.817 50.7648 281.274C47.0878 282.085 42.964 283.516 40.2419 281.94C37.5199 280.364 38.8959 274.945 38.9261 269.645C32.9903 268.919 26.5168 268.515 26.1909 265.388Z"
                fill="#FFFFFD"
            />
            <path
                d="M46.2476 260.404C53.5284 253.249 66.0595 244.228 67.4886 243.801C68.9177 243.375 68.8557 245.085 68.8557 245.085C59.4764 251.015 55.2114 255.297 48.1615 262.2C46.8571 262.28 46.3337 262.045 46.2476 260.404Z"
                fill="#0E196C"
            />
            <path
                d="M57.8199 272.054C65.148 264.949 74.4697 252.639 74.9308 251.221C75.3918 249.803 73.681 249.823 73.681 249.823C67.5259 259.056 63.1417 263.217 56.0702 270.098C55.9588 271.4 56.1807 271.929 57.8199 272.054Z"
                fill="#0E196C"
            />
            <path
                d="M84.6718 233.968C88.9517 233.977 93.1439 234.692 98.123 232.881L97.7995 241.86C93.4516 240.257 91.1238 241.806 84.4048 241.377C77.6858 240.948 82.2967 233.448 84.6718 233.968Z"
                fill="#F79E85"
            />
            <path
                d="M61.9133 240.353C40.0319 234.463 33.2123 233.852 12.3139 236.233C8.18147 242.405 6.69977 240.687 10.4808 247.548C34.0874 256.373 43.5237 256.863 62.0142 248.855C63.1475 246.337 63.1096 243.001 61.9133 240.353Z"
                fill="#071B81"
            />
            <path
                d="M138.647 259.058C138.322 255.931 99.3309 232.643 73.1185 237.812C68.168 242.149 68.7627 244.609 71.7958 249.012C86.8995 271.174 113.04 296.041 114.972 294.646C116.904 293.252 117.077 286.516 117.87 279.663C121.635 279.699 125.965 280.251 128.304 278.149C130.644 276.046 128.182 271.027 127.061 265.846C132.72 263.914 138.972 262.186 138.647 259.058Z"
                fill="#FFFFFD"
            />
            <path
                d="M117.997 258.309C109.399 252.807 95.2794 246.558 93.7931 246.434C92.3068 246.311 92.7194 247.972 92.7194 247.972C103.118 251.844 108.174 255.157 116.493 260.461C117.786 260.271 118.25 259.933 117.997 258.309Z"
                fill="#0E196C"
            />
            <path
                d="M109.07 272.093C100.436 266.647 88.7799 256.52 88.0367 255.227C87.2936 253.934 88.972 253.602 88.972 253.602C96.8958 261.371 102.042 264.539 110.379 269.818C110.756 271.069 110.648 271.632 109.07 272.093Z"
                fill="#0E196C"
            />
            <path
                d="M74.8371 241.77C70.5582 241.676 66.35 242.289 61.416 240.358L61.5227 249.343C65.908 247.845 68.1977 249.45 74.9252 249.184C81.6526 248.917 77.224 241.308 74.8371 241.77Z"
                fill="#F79E85"
            />
            <path
                d="M149.606 230.035C150.71 228.563 144.665 222.371 133.633 213.455C129.341 212.846 128.457 214.351 125.393 217.622C126.963 223.429 126.266 227.016 126.143 230.258C125.932 236.255 126.154 244.637 127.52 245.088C128.887 245.538 130.77 235.453 130.77 235.453C131.231 241.48 132.335 249.274 133.338 249.452C134.342 249.629 134.635 241.82 134.695 235.522C136.174 241.864 139.095 248.595 140.913 249.959C142.731 251.322 140.973 242.551 139.724 234.12C143.21 240.952 147.321 246.717 148.425 245.246C149.529 243.774 145.185 236.145 142.321 226.87C142.321 226.87 148.503 231.507 149.606 230.035Z"
                fill="#F79E85"
            />
            <path
                d="M8.43032 234.291C7.12024 233 12.1788 225.978 21.7651 215.523C25.9192 214.283 27.0163 215.641 30.5323 218.421C29.8421 224.397 31.0637 227.84 31.6664 231.028C32.7664 236.927 33.7915 245.249 32.5067 245.898C31.2219 246.546 27.8625 236.852 27.8625 236.852C28.3019 242.881 28.3668 250.752 27.4011 251.077C26.4354 251.402 24.9854 243.722 23.9912 237.503C23.4703 243.995 21.5816 251.085 19.9861 252.703C18.3906 254.322 18.8264 245.386 18.8099 236.864C16.3769 244.137 13.1671 250.449 11.8571 249.158C10.547 247.866 13.7104 239.677 15.1651 230.08C15.1651 230.08 9.74039 235.583 8.43032 234.291Z"
                fill="#F79E85"
            />
            <path
                d="M61.9535 109.5C61.1962 102.577 51.4551 103.5 46.9526 108C42.4502 112.5 45.4523 110 37.9525 115.5C30.4526 121 30.3754 124.178 34.7929 126.982C38.2221 129.16 44.9549 126.515 47.9526 124.5C50.2487 122.957 50.8702 120.195 52.7287 118.071C54.6437 115.883 57.2664 112.756 58.534 110.1C62.435 101.927 70.3196 97.9431 74.9535 90"
                stroke="#2E50CB"
                stroke-width="3"
                stroke-linecap="round"
            />
            <rect
                x="70.9551"
                y="85"
                width="14"
                height="9"
                rx="4"
                fill="#071B81"
            />
        </svg>
    );
};

export default DiverMeditating;
