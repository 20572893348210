import "./animations.css";

export const DiverLookdown = ({ headRef, ...props }) => {
    return (
        <svg {...props} viewBox="0 0 333 208" fill="none">
            <g id="Group 96">
                <g id="Group 83">
                    <path
                        id="Vector 15"
                        d="M225.778 70.4638C215.361 86.9601 213.071 92.3561 209.378 109.777C213.103 114.408 211.367 115.251 217.733 113.616C230.902 96.3332 233.793 88.7928 232.448 72.151C230.782 70.7091 228.165 70.0458 225.778 70.4638Z"
                        fill="#071B81"
                    />
                    <path
                        id="Vector 14"
                        d="M230.32 60.2931C229.109 63.739 228.47 67.2748 225.65 70.8691L232.699 72.6521C232.694 68.7888 234.557 67.2683 236.136 61.7643C237.716 56.2602 230.593 58.2636 230.32 60.2931Z"
                        fill="#F79E85"
                    />
                    <g id="Group 82">
                        <path
                            id="Vector 12"
                            d="M250.213 8.83014C247.594 8.74116 226.246 38.07 229.225 60.1523C232.585 64.7046 234.662 64.4964 238.479 62.5094C257.684 52.6416 279.697 34.0303 278.635 32.2877C277.572 30.5451 271.975 29.6379 266.311 28.2094C266.532 25.126 267.21 21.6378 265.578 19.4806C263.946 17.3234 259.645 18.772 255.277 19.1024C253.954 14.2421 252.832 8.91912 250.213 8.83014Z"
                            fill="#FFFFFD"
                        />
                    </g>
                </g>
                <g id="Group 95">
                    <path
                        id="Vector 15_2"
                        d="M149.27 57.7393C151.925 75.1662 151.714 80.5373 147.46 96.7876C141.783 99.7207 143.081 100.966 137.667 97.667C132.707 78.7448 133.3 71.3303 142.07 57.2423C144.327 56.4786 147.152 56.6721 149.27 57.7393Z"
                        fill="#071B81"
                    />
                    <g id="Group 82_2">
                        <path
                            id="Vector 12_2"
                            d="M93.3222 42.9747C94.5115 40.6977 132.684 32.0084 152.16 41.8164C154.84 46.1945 153.632 47.9085 149.893 50.5317C131.204 63.7733 102.864 76.5531 101.729 75.0689C100.595 73.5847 102.457 68.4831 103.857 63.1539C100.828 62.3307 97.1934 61.7669 95.9427 59.6582C94.692 57.5496 98.1563 54.3338 100.593 50.6938C96.6311 47.9627 92.1328 45.2518 93.3222 42.9747Z"
                            fill="#FFFFFD"
                        />
                        <path
                            id="Vector 16"
                            d="M110.1 47.0789C118.626 44.8868 131.8 43.3815 133.028 43.6247C134.256 43.8679 133.432 45.0225 133.432 45.0225C123.946 45.5827 118.91 46.9298 110.666 49.0358C109.686 48.6006 109.414 48.2423 110.1 47.0789Z"
                            fill="#0E196C"
                        />
                        <path
                            id="Vector 17"
                            d="M113.16 59.4541C121.698 57.3131 134.049 52.3368 135.029 51.5329C136.009 50.7291 134.762 50.1004 134.762 50.1004C126.103 54.1477 121.036 55.366 112.786 57.4485C112.112 58.3037 112.032 58.7514 113.16 59.4541Z"
                            fill="#0E196C"
                        />
                    </g>
                    <path
                        id="Vector 14_2"
                        d="M149.451 47.5492C149.073 50.905 148.103 54.1707 149.213 58.1299L141.604 57.6047C143.344 54.2437 142.227 52.3716 143.173 47.1158C144.118 41.8601 150.099 45.7025 149.451 47.5492Z"
                        fill="#F79E85"
                    />
                </g>
                <path
                    id="Vector 13"
                    d="M217.312 117.39C212.361 131.159 207.899 138.685 195.662 151.62C180.319 147.152 169.541 146.094 167.136 137.354C181.922 119.989 193.468 112.353 208.199 108.14C215.717 108.176 219.67 108.438 217.312 117.39Z"
                    fill="#071B81"
                />
                <path
                    id="Vector 42"
                    d="M136.472 97.3915C133.429 111.704 133.258 120.452 136.848 137.893C152.244 142.175 161.963 146.952 168.611 140.789C165.187 118.241 159.394 105.668 149.091 94.3284C142.681 90.4 139.183 88.5407 136.472 97.3915Z"
                    fill="#071B81"
                />
                <g id="Group 90">
                    <path
                        id="Ellipse 64"
                        d="M186.029 99.7139C186.029 112.14 176.18 122.214 164.029 122.214C151.879 122.214 142.029 112.14 142.029 99.7139C142.029 87.2875 151.879 77.2139 164.029 77.2139C176.18 77.2139 186.029 87.2875 186.029 99.7139Z"
                        fill="#F3CC3F"
                    />
                    <rect
                        id="Rectangle 29"
                        x="157.029"
                        y="96.7139"
                        width="13.3516"
                        height="5.36"
                        rx="2.68"
                        fill="#07167C"
                    />
                </g>
                <rect
                    id="Rectangle 39"
                    x="129.029"
                    y="121.214"
                    width="69"
                    height="46"
                    rx="21"
                    fill="#071B81"
                />
                <g id="Group 91">
                    <path
                        id="Vector 21"
                        d="M292.007 145.163C282.083 146.987 275.926 147.736 262.966 146.497C251.787 144.419 247.666 143.867 242.399 140.225C242.1 140.018 241.941 139.659 241.993 139.3C242.673 134.604 244.624 134.173 250.37 132.163C250.481 132.124 250.603 132.104 250.721 132.106C266.737 132.37 276.051 133.46 292.102 136.321C292.726 136.432 293.09 137.088 292.85 137.675C291.983 139.799 291.945 140.166 292.8 143.956C292.925 144.51 292.566 145.06 292.007 145.163Z"
                        fill="#F79E85"
                    />
                    <path
                        id="Vector 22"
                        d="M249.039 144.643C227.603 147.406 216.384 150.157 191.672 148.905C190.568 148.849 189.715 147.899 189.776 146.794C190.113 140.732 190.456 139.078 199.168 132.749C199.543 132.476 200.018 132.341 200.482 132.368C218.98 133.435 226.717 128.069 247.405 132.045C247.958 132.151 248.446 132.498 248.752 132.97C251.753 137.597 253.928 137.7 250.502 143.68C250.198 144.21 249.646 144.565 249.039 144.643Z"
                        fill="#F79E85"
                    />
                    <path
                        id="Vector 23"
                        d="M309.264 157.791C308.02 159.145 300.826 154.335 290.041 145.12C288.657 141.012 289.975 139.868 292.631 136.257C298.627 136.738 302.026 135.397 305.19 134.683C311.048 133.377 319.329 132.062 320.022 133.323C320.715 134.585 311.144 138.281 311.144 138.281C317.154 137.631 325.018 137.291 325.377 138.245C325.735 139.198 318.111 140.916 311.931 142.127C318.436 142.421 325.588 144.06 327.261 145.598C328.934 147.136 319.989 147.013 311.472 147.328C318.827 149.505 325.247 152.492 324.002 153.846C322.757 155.201 314.462 152.326 304.82 151.207C304.82 151.207 310.509 156.436 309.264 157.791Z"
                        fill="#F79E85"
                    />
                </g>
                <g id="Group 92">
                    <path
                        id="Vector 42_2"
                        d="M39.7137 145.163C49.6375 146.987 55.795 147.736 68.7545 146.497C79.9338 144.419 84.0552 143.867 89.3221 140.225C89.6207 140.018 89.7796 139.659 89.7276 139.3C89.0479 134.604 87.0964 134.173 81.351 132.163C81.2395 132.124 81.1173 132.104 80.9992 132.106C64.9841 132.37 55.6697 133.46 39.6189 136.321C38.995 136.432 38.6309 137.088 38.8705 137.675C39.7375 139.799 39.7756 140.166 38.9205 143.956C38.7955 144.51 39.1549 145.06 39.7137 145.163Z"
                        fill="#F79E85"
                    />
                    <path
                        id="Vector 43"
                        d="M79.4139 145.643C100.85 148.406 112.069 151.157 136.781 149.905C137.886 149.849 138.738 148.899 138.677 147.794C138.34 141.732 137.998 140.078 129.285 133.749C128.91 133.476 128.435 133.341 127.971 133.368C109.473 134.435 101.736 129.069 81.0477 133.045C80.4953 133.151 80.0071 133.498 79.701 133.97C76.7005 138.597 74.525 138.7 77.9515 144.68C78.2555 145.21 78.8075 145.565 79.4139 145.643Z"
                        fill="#F79E85"
                    />
                    <path
                        id="Vector 44"
                        d="M23.1575 157.791C24.4023 159.145 31.5964 154.335 42.3806 145.12C43.7645 141.012 42.4465 139.868 39.7909 136.257C33.7947 136.738 30.3962 135.397 27.2316 134.683C21.3741 133.377 13.0928 132.062 12.4 133.323C11.7072 134.585 21.278 138.281 21.278 138.281C15.268 137.631 7.40343 137.291 7.0453 138.245C6.68716 139.198 14.3109 140.916 20.4913 142.127C13.9857 142.421 6.8341 144.06 5.16076 145.598C3.48741 147.136 12.4325 147.013 20.9494 147.328C13.5951 149.505 7.17492 152.492 8.41973 153.846C9.66454 155.201 17.96 152.326 27.602 151.207C27.602 151.207 21.9127 156.436 23.1575 157.791Z"
                        fill="#F79E85"
                    />
                </g>
                <path
                    id="Rectangle 33"
                    d="M194.89 108.154C194.568 105.336 192.175 103.214 189.339 103.214V103.214C185.365 103.214 182.665 107.238 184.041 110.966C190.589 128.708 192.302 141.126 187.051 159.229C186.195 162.178 188.342 165.214 191.413 165.214V165.214C193.686 165.214 195.601 163.503 195.828 161.242C197.783 141.799 197.269 128.944 194.89 108.154Z"
                    fill="#2E50CB"
                />
                <path
                    id="Rectangle 40"
                    d="M136.169 111.154C136.491 108.336 138.884 106.214 141.72 106.214V106.214C145.693 106.214 148.394 110.238 147.018 113.966C140.47 131.708 138.757 144.126 144.008 162.229C144.863 165.178 142.716 168.214 139.645 168.214V168.214C137.372 168.214 135.458 166.503 135.231 164.242C133.276 144.799 133.79 131.944 136.169 111.154Z"
                    fill="#2E50CB"
                />
                <g id="head" ref={headRef}>
                    <path
                        id="tube"
                        d="M161.029 103.214C161.029 106.653 157.952 108.214 155.482 110.002C150.128 113.877 144.191 116.835 138.797 120.609C130.329 126.533 119.64 130.688 112.638 138.852C108.484 143.696 108.029 152.832 108.029 159.123C108.029 167.914 110.424 175.372 113.449 183.447C116.508 191.614 121.325 198.405 127.403 204C130.681 207.019 139.219 206.975 142.168 203.718C145.896 199.6 147.807 192.853 149.508 187.218C152.04 178.826 154.283 169.123 158.725 161.762"
                        stroke="#2E50CB"
                        stroke-width="3"
                        stroke-linecap="round"
                    />
                    <path
                        id="Vector 51"
                        d="M140.909 189.556C138.427 189.478 137.338 189.014 135.365 187.248C156.137 189.037 167.701 189.514 187.271 183.556C187.496 185.691 186.039 186.696 184.365 188.056C169.06 187.803 169.494 188.809 161.57 189.556C154.458 189.123 151.306 188.493 140.909 189.556Z"
                        fill="#E0E0E0"
                        stroke="#071B81"
                    />
                    <rect
                        id="Rectangle 42"
                        x="186.613"
                        y="173.342"
                        width="3"
                        height="10"
                        transform="rotate(13.932 186.613 173.342)"
                        fill="#071B81"
                    />
                    <rect
                        id="Rectangle 41"
                        x="132.242"
                        y="176.043"
                        width="3"
                        height="10"
                        transform="rotate(-13.4694 132.242 176.043)"
                        fill="#071B81"
                    />
                    <path
                        id="Ellipse 62"
                        d="M130.644 158.382C131.502 162.151 129.796 165.753 126.834 166.427C123.872 167.101 120.776 164.591 119.918 160.822C119.06 157.052 120.766 153.45 123.728 152.776C126.69 152.102 129.786 154.612 130.644 158.382Z"
                        fill="#F9AE91"
                    />
                    <path
                        id="Ellipse 63"
                        d="M200.812 158.184C199.803 161.916 196.608 164.298 193.676 163.506C190.743 162.713 189.184 159.045 190.193 155.313C191.202 151.581 194.397 149.198 197.329 149.991C200.261 150.784 201.821 154.452 200.812 158.184Z"
                        fill="#F9AE91"
                    />
                    <ellipse
                        id="Ellipse 65"
                        cx="159.865"
                        cy="151.556"
                        rx="37.5"
                        ry="36"
                        fill="#CE5425"
                    />
                    <path
                        id="Vector 48"
                        d="M123.355 156.056C116.207 129.884 116.681 116.686 123.355 95.0557C124.661 114.608 126.409 119.235 130.855 117.556C130.855 117.556 132.344 77.0557 142.855 77.0557C152.659 77.0557 160.27 104.9 161.248 108.642C160.736 104.223 172.165 81.8724 176.855 86.5557C181.718 91.4119 181.855 117.556 181.855 117.556C185.499 110.931 186.835 109.294 188.355 109.056C198.92 123.112 197.035 134.072 196.355 152.556L164.855 176.556L123.355 156.056Z"
                        fill="#CE5425"
                    />
                    <path
                        id="Vector 49"
                        d="M161.635 187.544C150.985 187.713 145.735 186.026 137.365 180.556C157.704 183.696 167.205 183.304 182.365 180.556C174.866 185.304 170.399 187.144 161.635 187.544Z"
                        fill="#F9AE91"
                    />
                    <path
                        id="Vector 50"
                        d="M187.02 181.556C171.491 186.87 165.166 189.007 134.031 183.999C131.889 184.469 131.732 183.608 134.031 187.054C157.168 192.945 170 190.631 187.02 185.221C188.781 183.317 188.846 183.46 187.02 181.556Z"
                        fill="#071B81"
                    />
                </g>
            </g>
        </svg>
    );
};
