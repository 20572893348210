import React from "react";

const AnglerFish = ({ isGlowing, ...props }) => {
    return (
        <svg
            {...props}
            viewBox="0 0 172 141"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M61.1745 134.482C57.8347 134.756 55.8417 134.592 51.2402 131.646L55.6845 125.716L61.6974 127.005C61.7986 130.172 61.7875 131.892 61.1745 134.482Z"
                fill="#6F292E"
            />
            <path
                d="M100.389 55.8443C87.8611 37.4194 74.3002 41.6417 52.8086 74.9222L58.0372 73.3762C78.0745 41.0845 87.0776 41.1398 100.911 57.9069L100.389 55.8443Z"
                fill="#6F292E"
            />
            <path
                d="M76.3363 70.2275C73.6847 69.0887 72.2773 68.6198 70.5849 69.4541C69.7284 69.4453 66.4276 63.7181 56.7292 68.6799C50.3048 72.1762 48.7409 74.9144 48.6249 80.7978C41.4018 92.3604 40.4377 100.173 49.4092 118.698L39.7363 122.566C47.0717 126.22 41.8278 124.112 55.1606 127.464C68.4934 130.817 88.9712 120.038 93.0677 96.2674C95.8032 93.0047 95.2931 91.4307 90.9763 89.0483C89.0504 87.4922 88.4361 86.3106 87.8391 83.8918L86.7934 74.3522C84.2682 68.731 82.0896 67.5084 76.3363 70.2275Z"
                fill="#A36165"
            />
            <path
                d="M84.179 74.9238C86.7939 74.9238 88.4232 80.3688 87.3161 87.8152C84.74 88.4896 83.3722 88.4885 81.3033 86.2682C79.5282 80.663 81.5641 74.9238 84.179 74.9238Z"
                fill="#D6D6D8"
            />
            <path
                d="M81.304 86.2515C79.2337 82.7279 79.5545 81.3475 81.5652 77.2275C84.1405 77.6389 85.1482 79.548 85.1866 81.3528C85.2251 83.1575 83.7565 84.9077 81.304 86.2515Z"
                fill="#29170A"
            />
            <path
                d="M72.4172 78.7905C75.6584 82.7197 74.358 93.1648 60.1301 91.6818C57.5384 86.8063 56.2091 82.1424 59.6073 78.5327C63.0054 74.9229 69.1761 74.8613 72.4172 78.7905Z"
                fill="#D6D6D8"
            />
            <path
                d="M34.7695 124.171C42.4716 124.941 46.468 122.609 53.3309 116.178C54.6307 116.136 55.0746 116.596 55.6838 117.725C55.8053 125.239 54.4062 129.063 48.6252 135C41.115 134.417 37.8119 132.521 34.7695 124.171Z"
                fill="#6F292E"
            />
            <path
                d="M42.8734 65.8994C32.1823 73.9899 28.0561 78.8879 25.6191 88.5881C34.7257 79.5366 40.0111 76.1901 49.6705 72.8607C46.7042 71.0349 45.1858 69.5381 42.8734 65.8994Z"
                fill="#6F292E"
            />
            <path
                d="M25.6193 88.5878C23.8389 90.2102 22.1586 90.8566 16.9922 91.1661V98.1274C20.9218 99.7564 21.6662 100.863 23.5279 102.768C29.1835 115.103 32.9902 119.456 40.7822 123.394C45.5856 123.564 47.2663 122.227 49.9321 120.043C43.6769 104.67 39.7712 96.0203 48.8864 81.8843C48.5378 77.8285 49.5868 75.7087 52.8079 72.0869C40.6584 75.0454 34.6525 78.5005 25.6193 88.5878Z"
                fill="#A36165"
            />
            <path
                d="M16.9928 98.901C14.4528 104.259 12.4787 106.514 7.58142 108.698C9.64032 100.603 7.29715 91.0393 0 69.5088C7.77053 72.8684 11.3412 78.3972 16.9928 91.6819V98.901Z"
                fill="#6F292E"
            />
            <g filter={isGlowing ? "url(#filter0_d_706_849)" : ""}>
                <ellipse
                    cx="101.759"
                    cy="66.2105"
                    rx="13.7586"
                    ry="14.2105"
                    fill={isGlowing ? "url(#paint0_radial_706_849)" : "#A1A1A1"}
                />
            </g>
            <ellipse
                cx="64.0489"
                cy="83.4319"
                rx="5.22856"
                ry="4.8987"
                fill="#29170A"
            />
            <mask
                id="mask0_706_849"
                maskUnits="userSpaceOnUse"
                x="79"
                y="77"
                width="7"
                height="10"
            >
                <path
                    d="M81.304 86.2524C79.2337 82.7288 79.5545 81.3485 81.5652 77.2285C84.1405 77.6399 85.1482 79.549 85.1866 81.3537C85.2251 83.1585 83.7565 84.9087 81.304 86.2524Z"
                    fill="#29170A"
                />
            </mask>
            <g mask="url(#mask0_706_849)">
                <path
                    d="M81.7649 80.1324C81.7649 80.7865 81.2369 81.3167 80.5856 81.3167C79.9342 81.3167 79.4062 80.7865 79.4062 80.1324C79.4062 79.4784 79.9342 78.9482 80.5856 78.9482C81.2369 78.9482 81.7649 79.4784 81.7649 80.1324Z"
                    fill="#D8D8DA"
                />
            </g>
            <path
                d="M62.8968 82.1053C62.8968 82.9773 62.1928 83.6843 61.3244 83.6843C60.4559 83.6843 59.752 82.9773 59.752 82.1053C59.752 81.2333 60.4559 80.5264 61.3244 80.5264C62.1928 80.5264 62.8968 81.2333 62.8968 82.1053Z"
                fill="#D8D8DA"
            />
            <path
                d="M65.8801 96.2151C54.9001 99.0515 47.5801 90.8008 47.5801 90.8008C47.5801 90.8008 54.3772 101.887 65.8801 100.856C77.3829 99.8245 90.4173 97.7683 92.8072 96.2151C95.197 94.6619 95.4214 92.6055 92.8072 90.8008C90.1929 88.996 76.86 93.3787 65.8801 96.2151Z"
                fill="#6F292E"
            />
            <defs>
                <filter
                    id="filter0_d_706_849"
                    x="32"
                    y="0"
                    width="139.518"
                    height="140.421"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="17"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_706_849"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="19.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_706_849"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_706_849"
                        result="shape"
                    />
                </filter>
                <radialGradient
                    id="paint0_radial_706_849"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(101.759 66.2105) rotate(88.4154) scale(14.2159 13.764)"
                >
                    <stop stop-color="#FFFADC" />
                    <stop offset="1" stop-color="#E5D476" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default AnglerFish;
