import React from "react";

const DiverRightSwim = (props) => {
    return (
        <svg {...props} viewBox="0 0 394 192" fill="none">
            <path
                d="M280.57 87.588C282.321 85.9084 282.564 83.2012 281.176 81.2111C279.323 78.5514 275.448 78.3638 273.267 80.7625C245.953 110.801 235.842 109.266 208.555 81.7424C206.675 79.846 203.403 81.1811 203.449 83.851C203.462 84.6363 203.803 85.3973 204.373 85.9377C234.508 114.515 249.095 117.789 280.57 87.588Z"
                fill="#2E50CB"
            />
            <path
                d="M353.537 107.625C343.629 105.718 337.478 104.918 324.508 106.049C313.312 108.033 309.186 108.551 303.889 112.149C303.589 112.353 303.427 112.71 303.476 113.07C304.116 117.771 306.064 118.219 311.793 120.277C311.904 120.317 312.026 120.338 312.144 120.337C328.161 120.207 337.484 119.195 353.558 116.468C354.183 116.362 354.552 115.709 354.318 115.12C353.468 112.989 353.433 112.622 354.32 108.839C354.45 108.286 354.095 107.733 353.537 107.625Z"
                fill="#F79E85"
            />
            <path
                d="M315.112 108.757C297.962 95.6041 289.642 87.5914 267.642 76.2651C266.659 75.7587 265.444 76.153 264.942 77.1389C262.189 82.5499 261.655 84.1524 266.011 94.001C266.199 94.4257 266.542 94.7807 266.956 94.9904C283.489 103.356 287.485 111.881 307.373 118.831C307.904 119.017 308.501 118.962 309.002 118.708C313.921 116.213 315.854 117.216 315.893 110.325C315.896 109.713 315.597 109.13 315.112 108.757Z"
                fill="#F79E85"
            />
            <path
                d="M373.685 97.7041C372.786 96.0991 364.676 99.1203 352.058 105.6C349.764 109.278 350.782 110.695 352.533 114.821C358.479 115.736 361.476 117.825 364.391 119.249C369.79 121.871 377.544 125.06 378.509 123.993C379.474 122.925 371.014 117.121 371.014 117.121C376.712 119.14 384.286 121.285 384.855 120.439C385.423 119.594 378.401 116.164 372.667 113.56C379.064 114.775 386.401 114.829 388.384 113.718C390.367 112.608 381.635 110.665 373.42 108.394C381.078 107.972 388.014 106.546 387.115 104.941C386.217 103.336 377.482 104.221 367.842 103.085C367.842 103.085 374.584 99.3091 373.685 97.7041Z"
                fill="#F79E85"
            />
            <rect
                x="276.379"
                y="33.7256"
                width="13.3516"
                height="5.36"
                rx="2.68"
                transform="rotate(88.4967 276.379 33.7256)"
                fill="#07167C"
            />
            <rect
                width="19.2812"
                height="5.34067"
                rx="2.67034"
                transform="matrix(-0.999996 0.00266876 -0.00249781 -0.999997 274.637 43.4756)"
                fill="#07167C"
            />
            <path
                d="M248.579 20.9251C259.004 21.1766 267.336 29.6185 267.609 40.0431C267.904 51.2826 258.777 60.5038 247.535 60.3234L205.035 59.6412L163.64 62.5125C152.456 63.2882 142.881 54.5866 142.587 43.3801C142.313 32.9424 150.241 24.0849 160.653 23.3053C191.97 20.9604 215.566 20.1288 248.579 20.9251Z"
                fill="#F7BA16"
            />
            <path
                d="M142.237 87.3381C169.154 95.0884 184.618 97.8221 213.141 98.6105C211.638 81.5894 214.331 71.4443 199.612 61.7772C162.021 59.3142 143.338 62.5474 129.462 71.4857C126.32 77.9514 125.105 81.5629 142.237 87.3381Z"
                fill="#071B81"
            />
            <path
                d="M192.74 61.2406C227.186 61.7443 244.162 62.3295 273.122 56.2911C287.635 68.945 287.368 78.138 275.374 95.3923C246.653 99.2557 229.219 101.69 194.973 100.021C175.618 87.0417 174.698 78.509 192.74 61.2406Z"
                fill="#071B81"
            />
            <path
                d="M307.518 130.876C300.956 123.212 296.642 118.754 285.792 111.56C275.798 106.137 272.248 103.972 265.862 103.487C265.5 103.459 265.151 103.638 264.965 103.95C262.538 108.027 263.783 109.59 266.982 114.769C267.044 114.869 267.126 114.962 267.219 115.034C279.83 124.91 287.753 129.926 302.026 137.806C302.581 138.113 303.277 137.832 303.46 137.225C304.123 135.029 304.325 134.72 307.375 132.313C307.821 131.961 307.888 131.308 307.518 130.876Z"
                fill="#F79E85"
            />
            <path
                d="M330.22 133.27C330.267 131.431 321.753 129.883 307.595 129.003C303.744 130.992 303.895 132.731 303.291 137.172C307.934 140.997 309.443 144.325 311.22 147.039C314.521 152.051 319.556 158.756 320.931 158.332C322.306 157.907 318 148.595 318 148.595C321.867 153.242 327.281 158.956 328.201 158.52C329.122 158.084 324.839 151.547 321.241 146.378C326.119 150.692 332.398 154.488 334.67 154.547C336.942 154.606 330.429 148.473 324.529 142.323C331.327 145.874 338.018 148.193 338.065 146.354C338.113 144.515 330.153 140.811 322.447 134.908C322.447 134.908 330.172 135.109 330.22 133.27Z"
                fill="#F79E85"
            />
            <path
                d="M269.916 57.2013C271.666 55.5217 271.909 52.8145 270.522 50.8243C268.668 48.1647 264.793 47.9771 262.612 50.3758C235.299 80.4143 225.188 78.8794 197.901 51.3556C196.021 49.4593 192.749 50.7944 192.794 53.4643C192.808 54.2496 193.149 55.0105 193.719 55.551C223.854 84.1283 238.44 87.4026 269.916 57.2013Z"
                fill="#2E50CB"
            />
            <path
                d="M90.6833 44.4691C112.183 51.6287 118.208 54.881 134.324 68.3967C134.366 75.8245 136.549 75.2091 129.611 78.8455C105.053 73.1816 96.9124 68.3847 85.9087 51.5043C86.3529 48.7786 88.2245 46.0178 90.6833 44.4691Z"
                fill="#071B81"
            />
            <path
                d="M12.4361 53.7717C12.7898 50.6471 51.9948 27.7205 78.1583 33.1315C83.0685 37.5143 82.4511 39.9684 79.3776 44.3429C64.0698 66.3646 37.7006 90.9888 35.7817 89.5769C33.8627 88.1651 33.7519 81.4271 33.0227 74.5669C29.2574 74.5689 24.9223 75.0807 22.6024 72.9567C20.2826 70.8327 22.7907 65.8362 23.9592 60.6661C18.318 58.6814 12.0825 56.8963 12.4361 53.7717Z"
                fill="#FFFFFD"
            />
            <path
                d="M33.0947 53.2136C41.7427 47.7909 55.9195 41.6727 57.4069 41.5631C58.8943 41.4534 58.4664 43.11 58.4664 43.11C48.032 46.8863 42.9465 50.1522 34.578 55.3794C33.2868 55.1771 32.8261 54.8355 33.0947 53.2136Z"
                fill="#0E196C"
            />
            <path
                d="M41.894 67.0783C50.5777 61.713 62.3266 51.6941 63.0817 50.408C63.8367 49.1218 62.1614 48.7743 62.1614 48.7743C54.1662 56.4693 48.9905 59.5904 40.6056 64.7912C40.217 66.039 40.3201 66.6032 41.894 67.0783Z"
                fill="#0E196C"
            />
            <path
                d="M79.123 38.5211C82.7439 40.8031 85.9152 43.6365 91.0957 44.748L86.0501 52.1824C83.2187 48.514 80.4236 48.5894 74.9597 44.6555C69.4958 40.7216 77.3875 36.8183 79.123 38.5211Z"
                fill="#F79E85"
            />
            <path
                d="M143.71 101.249C163.487 83.7128 175.632 75.1461 199.976 63.0758C206.439 78.6056 213.399 86.4302 205.062 100.781C173.629 117.938 155.973 122.555 139.878 120.25C134.208 115.832 131.508 113.149 143.71 101.249Z"
                fill="#071B81"
            />
            <path
                d="M22.2289 121.081C22.1622 117.937 57.9552 89.9811 84.6069 91.8504C90.0583 95.5384 89.7741 98.0529 87.3121 102.799C75.0817 126.667 52.2362 154.591 50.146 153.448C48.0557 152.305 47.0463 145.643 45.4077 138.941C41.6763 139.446 37.4483 140.532 34.8657 138.737C32.283 136.941 34.1016 131.655 34.5693 126.375C28.7137 125.161 22.2956 124.225 22.2289 121.081Z"
                fill="#FFFFFD"
            />
            <path
                d="M42.6263 117.769C50.4728 111.24 63.7058 103.284 65.1652 102.977C66.6247 102.67 66.4218 104.369 66.4218 104.369C56.585 109.504 51.9811 113.42 44.3854 119.718C43.0788 119.689 42.5766 119.412 42.6263 117.769Z"
                fill="#0E196C"
            />
            <path
                d="M53.1984 130.335C61.088 123.859 71.394 112.361 71.9705 110.986C72.5471 109.61 70.8404 109.489 70.8404 109.489C63.9442 118.183 59.2316 121.967 51.6161 128.241C51.3976 129.529 51.5751 130.075 53.1984 130.335Z"
                fill="#0E196C"
            />
            <path
                d="M95.706 99.0018C118.365 99.225 125.097 100.473 144.581 108.397C146.898 115.454 148.788 114.199 143.298 119.788C118.187 121.926 108.967 119.856 93.3182 107.162C92.9053 104.431 93.8403 101.23 95.706 99.0018Z"
                fill="#071B81"
            />
            <path
                d="M82.8803 96.8843C87.0264 97.9463 90.9137 99.6709 96.1855 99.1406L93.6622 107.764C89.8425 105.14 87.2051 106.069 80.7983 104C74.3914 101.93 80.7064 95.7956 82.8803 96.8843Z"
                fill="#F79E85"
            />
            <path
                d="M290.328 77.1905C286.04 64.7217 292.881 46.8185 313.701 34.0874C322.28 29.4789 327.666 28.8474 338.421 32.4861C341.585 33.5564 344.3 35.6408 346.063 38.4767C350.274 45.2491 356.594 57.29 358.704 68.2852C359.029 69.9826 359.011 71.7328 358.634 73.4194C356.27 83.989 350.308 88.9743 337.545 94.0428C314.542 101.833 294.615 89.6593 290.328 77.1905Z"
                fill="#F9AE91"
            />
            <path
                d="M333.006 47.17C325.006 53.9336 318.886 57.6791 290.094 63.8976C297.769 33.2304 345.996 14.601 339.546 24.1353C333.097 33.6696 351.884 24.0697 351.884 24.0697C351.884 24.0697 372.841 15.9586 366.87 21.4902C360.9 27.0218 360.974 41.0217 360.974 41.0217C360.974 41.0217 372.086 31.505 374.612 36.4916C376.513 40.244 371.273 49.3897 368.563 53.6872C369.73 53.2956 371.635 53.0076 374.7 52.9914C384.699 52.9384 355.686 81.0503 355.686 81.0503C352.701 67.2544 350.092 59.6093 333.006 47.17Z"
                fill="#CE5425"
            />
            <path
                d="M315.054 62.3162C311.706 63.9134 308.178 62.9997 307.034 60.6027C305.89 58.2058 307.4 54.8881 310.747 53.2909C314.095 51.6937 317.623 52.6075 318.767 55.0044C319.911 57.4013 318.401 60.719 315.054 62.3162Z"
                fill="#F9AE91"
                stroke="#F79E85"
            />
            <path
                d="M352.675 89.2283C351.104 77.5643 347.998 70.2428 342.303 58.8252C341.116 56.445 339.542 53.966 336.969 53.2933C335.774 52.9811 334.504 53.0054 332.81 53.2471C328.848 54.0874 326.426 54.9192 323.636 56.9298C322.142 58.0061 320.93 59.4382 320.08 61.0714C318.364 64.373 318.431 66.0173 319.257 68.1622C320.539 71.2685 321.061 72.2623 321.915 73.7329C322.487 74.7527 323.018 75.3399 323.665 75.7024C324.969 76.4333 326.579 75.9852 328.072 76.0559C329.012 76.1005 329.733 76.371 330.461 76.797C331.002 77.1141 331.407 77.6157 331.677 78.1822C332.112 79.0934 332.183 80.1651 331.699 81.0507C331.306 81.7687 330.88 82.2933 330.296 82.7763C329.655 83.3071 328.978 83.8058 328.455 84.4535C328.146 84.8361 327.889 85.1865 327.674 85.5156C326.269 87.667 327.103 90.4308 328.167 92.7693C328.507 93.5141 328.823 94.1875 329.137 94.8327C330.388 97.4065 332.225 99.9586 335 100.658C336.275 100.98 337.375 100.859 338.812 100.67C342.442 99.8741 344.749 99.0975 347.436 97.6514C349.239 96.6809 350.869 95.3353 351.888 93.5588C352.767 92.026 352.962 90.8088 352.675 89.2283Z"
                fill="#071B81"
            />
            <path
                d="M350.079 87.9797C348.388 77.7709 345.606 71.2796 340.58 61.1548C339.505 58.9877 338.144 56.6697 335.814 56.0168C334.886 55.7566 333.898 55.7446 332.614 55.8857C329.711 56.4233 327.841 56.9997 325.874 58.3384C324.245 59.4473 322.998 61.055 322.25 62.8783C321.257 65.2995 321.406 66.6598 322.099 68.416C323.254 71.1713 323.714 72.0568 324.462 73.3701C325.094 74.5156 326.5 74.3231 327.826 74.1379C329.059 73.9656 330.333 73.8009 331.573 73.9106C331.923 73.9416 332.241 74.0099 332.541 74.1115C333.762 74.5257 334.481 75.7348 334.926 76.9457C335.463 78.4036 335.631 80.0514 335.056 81.4947C334.99 81.6607 334.916 81.8175 334.829 81.9661C334.391 82.7135 333.643 83.2325 333.067 83.8798C332.455 84.5681 331.823 85.2499 331.277 85.9099C329.827 87.6611 330.35 90.4667 331.327 92.5198C332.412 94.8013 333.756 96.6533 336.184 97.354C337.19 97.6446 338.062 97.5788 339.188 97.4692C341.743 96.976 343.478 96.46 345.298 95.5629C347.305 94.5739 349.167 93.0598 349.929 90.9565C350.283 89.9792 350.31 89.0853 350.079 87.9797Z"
                fill="#FCD7CB"
            />
            <ellipse
                cx="330.939"
                cy="65.9628"
                rx="2.90466"
                ry="3.64989"
                transform="rotate(64.4918 330.939 65.9628)"
                fill="#171F61"
            />
            <ellipse
                cx="339.48"
                cy="87.6328"
                rx="2.90466"
                ry="3.64989"
                transform="rotate(64.4918 339.48 87.6328)"
                fill="#171F61"
            />
            <path
                d="M337.894 62.6442C337.25 61.405 336.624 60.7417 335.433 60.0441C334.832 59.6923 333.965 59.8201 333.799 60.496C333.623 61.2092 334.41 61.8324 334.987 62.2885L334.988 62.2894C335.171 62.4344 335.376 62.6102 335.61 62.819C336.035 63.1987 336.285 63.7412 336.283 64.3112C336.282 64.4256 336.281 64.5333 336.277 64.6347C336.249 65.476 336.181 66.7405 337.018 66.8254C337.524 66.8767 337.956 66.4318 338.112 65.9484C338.435 64.9455 338.431 64.1111 337.894 62.6442Z"
                fill="#BD5930"
            />
            <path
                d="M346.703 85.6066C346.399 84.24 345.969 83.4463 345.012 82.4983C344.525 82.0157 343.657 81.9534 343.331 82.5943C342.992 83.2619 343.583 84.0438 344.016 84.6157C344.156 84.8019 344.311 85.0251 344.486 85.2889C344.795 85.756 344.896 86.3444 344.753 86.9075C344.723 87.0265 344.693 87.1377 344.664 87.2417C344.428 88.0642 344.046 89.2946 344.82 89.5654C345.293 89.7311 345.821 89.3771 346.092 88.9264C346.646 88.0039 346.848 87.1749 346.703 85.6066Z"
                fill="#BD5930"
            />
            <path
                d="M309.317 72.6415C306.873 70.7664 305.364 70.3509 302.149 72.0932"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M305.242 71.0684C303.006 81.7888 305.511 85.5961 312.541 90.8538"
                stroke="#EF8267"
                stroke-width="1.5"
            />
            <path
                d="M287.577 77.9742C265.903 82.3735 254.196 94.43 201.41 94.4403C201.106 94.4404 200.809 94.2979 200.625 94.0558C200.133 93.4067 200.638 92.4759 201.452 92.4905C251.4 93.389 264.074 81.9574 287.191 76.1751C287.908 75.9959 288.529 76.6929 288.272 77.3852C288.159 77.6875 287.893 77.91 287.577 77.9742Z"
                fill="#FFFFFD"
            />
            <path
                d="M281.132 66.2383C286.227 68.1984 289.332 68.7685 295.331 68.7761L294.129 81.3515C288.79 81.0764 285.626 81.4202 279.536 83.2752C276.577 76.6187 276.536 72.8956 281.132 66.2383Z"
                fill="#F9AE91"
                stroke="#F9AE91"
            />
            <path
                d="M275.231 107.748C273.164 86.2343 273.354 74.6846 266.644 50.868C266.344 49.8032 265.227 49.1831 264.164 49.4882C258.329 51.1628 256.792 51.8645 252.556 61.7651C252.373 62.1921 252.347 62.6849 252.476 63.1311C257.626 80.9298 254.113 89.6654 262.585 108.954C262.812 109.469 263.258 109.868 263.786 110.061C268.964 111.959 269.548 114.057 274.617 109.388C275.067 108.974 275.29 108.357 275.231 107.748Z"
                fill="#F79E85"
            />
            <path
                d="M203.413 98.5446C198.567 86.0443 197.438 78.3632 198.818 64.7138C198.868 64.2196 198.495 63.7788 197.999 63.7551C197.522 63.7324 197.117 64.1058 197.097 64.5831C196.535 78.2988 197.47 86.2629 201.48 99.2254C201.639 99.7409 202.181 100.038 202.701 99.8927L202.749 99.8793C203.321 99.7198 203.627 99.098 203.413 98.5446Z"
                fill="#2E50CB"
            />
            <path
                d="M263.283 46.1006C267.596 49.8936 272.1 52.4961 275.494 57.4409C279.191 62.8283 283.314 67.9212 285.689 74.0939C287.908 79.8592 287.69 87.0062 292.354 91.6216C293.598 92.8517 295.039 94.148 296.841 94.1384C297.996 94.1323 300.419 93.2855 300.807 92.1015C301.254 90.7368 302.701 88.9688 302.772 87.6451C302.869 85.8264 304.027 85.6035 304.742 84.1552"
                stroke="#2E50CB"
                stroke-width="3"
                stroke-linecap="round"
            />
            <rect
                x="308.52"
                y="74.8711"
                width="14"
                height="9"
                rx="4"
                transform="rotate(65.3917 308.52 74.8711)"
                fill="#071B81"
            />
        </svg>
    );
};

export default DiverRightSwim;
