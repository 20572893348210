import React from "react";

export const InvertedCrab = (props) => {
    return (
        <svg {...props} viewBox="0 0 110 103" fill="none">
            <path
                d="M96.1506 27.5261C95.6895 35.6677 92.997 38.7798 81.6412 41.238L77.1311 36.8864C86.9362 35.2931 93.5086 35.0997 93.1739 26.9073C92.9871 24.289 95.3525 22.4801 96.1506 27.5261Z"
                fill="#DD7571"
            />
            <path
                d="M20.0373 28.394C20.0919 36.5298 22.6304 39.5821 33.8698 41.7826L38.6 37.3256C28.8689 35.9552 22.3024 35.9116 23.0466 27.7069C23.3643 25.0829 21.088 23.3269 20.0373 28.394Z"
                fill="#DD7571"
            />
            <path
                d="M86.7757 18.1367C88.5863 26.0376 86.8247 29.6651 76.4336 34.7585L70.8275 31.6839C79.9466 27.7924 86.3019 26.0206 83.7012 18.2633C82.7922 15.8033 84.5965 13.5016 86.7757 18.1367Z"
                fill="#DD7571"
            />
            <path
                d="M29.887 18.785C27.6805 26.7317 29.2619 30.3211 39.4044 35.1803L45.1673 31.976C36.2375 28.2904 29.9671 26.6626 32.9569 18.8415C33.9893 16.3594 32.2989 14.0976 29.887 18.785Z"
                fill="#DD7571"
            />
            <path
                d="M75.1543 14.0342C77.6723 21.7428 76.2369 25.4842 66.308 31.3548L60.4291 28.7372C69.1945 24.1599 75.3876 21.904 72.0928 14.3997C70.9637 12.0265 72.5607 9.59896 75.1543 14.0342Z"
                fill="#DD7571"
            />
            <path
                d="M41.7204 14.4154C38.8157 22.1859 40.065 25.8967 49.7062 31.5441L55.7192 28.7908C47.1776 24.4109 41.0937 22.2951 44.7654 14.7113C46.0137 12.311 44.537 9.9185 41.7204 14.4154Z"
                fill="#DD7571"
            />
            <path
                d="M91.2613 37.8478C87.9608 39.7625 86.2881 40.1748 81.5071 41.0703L76.9445 36.7666C82.5583 36.3 85.5998 35.9603 89.3526 34.136C89.5928 35.491 89.3432 36.555 91.2613 37.8478Z"
                fill="#CA5F5C"
            />
            <path
                d="M24.4137 38.6105C27.6204 40.451 29.2735 40.8254 34.0124 41.6123L38.7926 37.2019C33.1989 36.8632 30.1728 36.5927 26.509 34.853C26.2009 36.2143 26.3975 37.2732 24.4137 38.6105Z"
                fill="#CA5F5C"
            />
            <path
                d="M84.872 29.1931C82.185 31.8218 80.6683 32.6201 76.2548 34.6311L70.6108 31.615C75.9556 29.8133 78.8271 28.754 81.9802 26.1026C82.5906 27.3411 82.6426 28.4193 84.872 29.1931Z"
                fill="#CA5F5C"
            />
            <path
                d="M31.2372 29.8042C33.7943 32.3732 35.272 33.1373 39.5875 35.0488L45.3854 31.9021C40.1276 30.2214 37.3074 29.227 34.285 26.646C33.6124 27.8991 33.5065 28.9791 31.2372 29.8042Z"
                fill="#CA5F5C"
            />
            <path
                d="M74.2443 25.1675C71.7945 27.9887 70.3502 28.9001 66.1193 31.2422L60.2079 28.6856C65.3883 26.4789 68.1633 25.2026 71.077 22.3225C71.7981 23.5053 71.9468 24.5725 74.2443 25.1675Z"
                fill="#CA5F5C"
            />
            <path
                d="M42.0738 25.5353C44.3841 28.3022 45.7837 29.1812 49.8999 31.4281L55.9425 28.7351C50.8694 26.6453 48.1565 25.4316 45.3851 22.6163C44.6045 23.8163 44.4024 24.8875 42.0738 25.5353Z"
                fill="#CA5F5C"
            />
            <path
                d="M91.2348 65.7329C95.5995 58.1708 94.4309 53.8469 83.1023 45.1738L75.4064 46.5058C85.5056 53.5201 92.7998 57.4377 87.5074 64.5919C85.726 66.8329 87.3076 69.8022 91.2348 65.7329Z"
                fill="#C35651"
            />
            <path
                d="M73.0759 71.4339C75.2886 72.3508 77.5363 71.8957 81.5954 71.0383L90.0726 58.7081C79.1731 59.5171 73.4376 60.3334 73.0759 71.4339Z"
                fill="#CA5856"
            />
            <path
                d="M94.9595 72.9644C97.7933 63.8939 93.3115 58.9814 89.3032 59.0281C85.2948 59.0747 80.8538 67.2138 80.0453 69.0898C79.2369 70.9658 82.8924 78.213 76.0096 83.759C86.8187 83.1829 91.5952 81.5402 94.9595 72.9644Z"
                fill="#DC7371"
            />
            <path
                d="M22.8215 66.5127C18.8321 59.046 20.2175 54.6929 31.9859 45.7563L39.6196 46.9135C29.1642 54.1622 21.6701 58.2485 26.608 65.286C28.2785 67.4877 26.5476 70.4947 22.8215 66.5127Z"
                fill="#C35651"
            />
            <path
                d="M40.7048 71.8032C38.445 72.7712 36.2187 72.3671 32.2002 71.6019L24.3343 59.4581C35.1995 60.0188 40.8975 60.7047 40.7048 71.8032Z"
                fill="#CA5856"
            />
            <path
                d="M18.7331 73.8337C16.3509 64.8228 21.0807 59.8052 25.089 59.7604C29.0973 59.7155 33.1342 67.758 33.8494 69.6166C34.5646 71.4752 30.5449 78.8099 37.1545 84.202C26.368 83.8723 21.6708 82.3377 18.7331 73.8337Z"
                fill="#DC7371"
            />
            <path
                d="M57.6744 60.3218C76.0797 59.4318 80.9236 53.8324 85.8588 40.3991C71.2475 20.1501 44.7318 21.0233 29.1347 41.0462C33.1215 56.1004 39.5282 60.1549 57.6744 60.3218Z"
                fill="#DB7370"
            />
            <path
                d="M68.2597 50.5496C68.2437 51.1934 68.0315 52.0567 67.3885 52.1191C66.5281 52.2027 66.2455 51.0598 66.267 50.1983C66.2858 49.4476 66.5368 48.4797 67.2855 48.3932C67.3218 48.389 67.3577 48.3876 67.3934 48.3891C68.1266 48.4192 68.2884 49.4029 68.27 50.138L68.2597 50.5496Z"
                fill="#3D4848"
            />
            <path
                d="M48.2402 50.7782C48.2241 51.4219 48.012 52.2852 47.369 52.3477C46.5086 52.4312 46.226 51.2883 46.2475 50.4268C46.2663 49.6761 46.5173 48.7082 47.266 48.6217C47.3022 48.6175 47.3381 48.6162 47.3738 48.6176C48.1071 48.6477 48.2688 49.6315 48.2505 50.3665L48.2402 50.7782Z"
                fill="#3D4848"
            />
        </svg>
    );
};
